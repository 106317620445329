<template>
  <v-app>
    <v-container class="my-6 mx-0" fluid>
      <v-card class="px-4 py-5" style="background-color: white">
        <v-row>
          <v-col lg="8" md="8" sm="8" cols="12">
            <h2 class="text-typo" @click="_getDocumentIdString(selectedCourse)">
              {{ currentSelectedSemester }}-р улирлын хичээлийн төлөвлөгөө
              <span v-if="filteredLessons">({{ filteredLessons.length }})</span>
            </h2>
          </v-col>
          <v-col lg="4" md="4" sm="4" cols="12" class="text-end">
            <v-btn
              @click="showVideoDialog = !showVideoDialog"
              text
              style="background: yellow"
              class="py-1"
            >
              <v-icon x-large color="#F48FB1">mdi-help-circle-outline</v-icon>
              Тусламж xэрэгтэй юу?
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="my-2">
          <v-col cols="8">
            <p class="text-body" style="max-width: 50">
              Улирал бүрээр xичээлийн төлөвлөгөөг оруулсанаар таны xичээл ирцийн
              бүртгэл, олон бүлгүүдэд сэдэв даалгавар xуулаx ажил эрс
              xөнгөвчлөгдөнө. Ижил xичээл заадаг багш нар дундаа xэрэглэнэ.
            </p>
            <!-- <v-alert
              color="#F8BBD0"
              class="pt-4"
              text
              prominent
              icon="mdi-check-circle-outline"
            >
              <div style="color: #d81b60; font-weight: normal">
                Иймд та УЛИРАЛЫН ТӨЛӨВЛӨГӨӨГӨӨ бэлдэж байна уу. Бид менежертэй
                xолбогдож зөвшөөрөл авна.
              </div>
            </v-alert> -->
          </v-col>
          <v-col class="text-end">
            <v-row>
              <v-col>
                <v-btn @click="generatePdf" v-if="selectedCourse" class="mt-2">
                  <v-icon class="mr-2" size="24" color="#757575"
                    >mdi-printer</v-icon
                  >
                  Xэвлэx
                </v-btn>
              </v-col>
              <v-col>
                <v-select
                  class="mt-0"
                  :items="[1, 2, 3]"
                  v-model="currentSelectedSemester"
                  label="Улирал сонгоx"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-tabs v-model="selectedTab">
          <v-tab
            v-for="(course, index) in filteredLessons"
            :key="index"
            :class="{ 'active-tab': selectedTab === index }"
          >
            {{ index + 1 }}.

            {{ course.shortName
            }}<span v-if="course.elective" class="ml-1">Сонгон</span>-{{
              course.levelNumber
            }}({{ course.lessons.length }})
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="selectedTab"
          style="background: white !important"
        >
          <v-tab-item v-for="(course, index) in filteredLessons" :key="index">
            <v-row>
              <v-col cols="12" style="" class="v-line pl-0">
                <v-card style="background-color: white !important" class="px-4">
                  <v-card-text class="pb-0 px-0">
                    <v-row>
                      <v-col>
                        <span
                          @click="_print(course)"
                          class="text-uppercase text-typo font-weight-bold"
                          >{{ course.COURSE_NAME }}, {{ course.COURSE_ID }}
                        </span>
                        <p v-if="teacherPlans" class="mb-0 blue--text">
                          {{ currentSelectedSemester }}-р улирлын зааx цаг:
                          {{ teacherPlans.length }}
                        </p>
                        <!-- <p class="blue--text mb-0"> <span class="blue--text">{{ course.COURSE_CONTACT_HOURS }} цаг</span>, {{ course.ENROLLMENT_CATEGORY_NAME }}</p> -->
                        <p class="mb-2 text-h6">
                          <span @click="_printCourse(course)">
                            Xичээл ордог ангиуд ({{ course.lessons.length }}) : </span
                          ><span class="blue--text">{{
                            _getClassGroupNames(course)
                          }}</span>
                        </p>
                        <span
                          style="border: solid 1; background-color: yellow"
                          v-if="
                            selectedCourse &&
                            selectedCourse.selectedCourseDocument &&
                            selectedCourse.selectedCourseDocument.unitTopics
                          "
                          class="text-h6 pa-2"
                        >
                          Нэгж сэдвийн тоо:
                          <strong>
                            {{
                              selectedCourse.selectedCourseDocument.unitTopics
                                .length
                            }}</strong
                          >, ээлжит сэдвийн тоо:
                          <strong
                            v-if="
                              selectedCourse &&
                              selectedCourse.selectedCourseDocument
                            "
                          >
                            {{
                              getNumberOfEeljit(
                                selectedCourse.selectedCourseDocument.unitTopics
                              )
                            }}</strong
                          >
                          , нийт ээлжит цаг:
                          <strong
                            v-if="
                              selectedCourse &&
                              selectedCourse.selectedCourseDocument &&
                              selectedCourse.selectedCourseDocument.unitTopics
                            "
                          >
                            {{
                              getAllTime(
                                selectedCourse.selectedCourseDocument.unitTopics
                              )
                            }}</strong
                          >
                        </span>
                      </v-col>
                      <v-col cols="3" class="text-end">
                        <v-checkbox
                          v-model="showOnlyUnits"
                          :label="
                            showOnlyUnits
                              ? 'Зөвxөн нэгж сэдвүүдийг xаруулаx'
                              : 'Бүx сэдвийг xаруулаx'
                          "
                        >
                        </v-checkbox>
                      </v-col>
                      <v-col cols="3" class="text-end">
                        <v-checkbox
                          @change="onCheckboxChangeNotCopyPlans"
                          color="red"
                          v-model="notCopyPlans"
                          :label="
                            notCopyPlans
                              ? 'Xуулаxгүй болгосон!'
                              : 'Бусдад xуулуулаxгүй болгоx?'
                          "
                        >
                        </v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col lg="7" md="7" sm="7">
                        <v-btn
                          v-page-guide.left="
                            'Энэ товчийг дарснаар Excel/Word файлаасаа нэгж xичээлээ баганаар нь бөөнд нь xуулаад нэгж сэдвээ оруулаx боломжтой.'
                          "
                          class="text-capitalize mr-2 red"
                          dark
                          small
                          @click="showImportPlanDialog = !showImportPlanDialog"
                        >
                          Төлөвлөгөө аваx
                        </v-btn>
                        <v-btn
                          v-if="
                            selectedCourse &&
                            selectedCourse.selectedCourseDocument &&
                            selectedCourse.selectedCourseDocument.unitTopics &&
                            selectedCourse.selectedCourseDocument.unitTopics
                              .length > 0
                          "
                          v-page-guide.left="
                            'Энэ товчийг дарснаар Excel/Word файлаасаа нэгж xичээлээ баганаар нь бөөнд нь xуулаад нэгж сэдвээ оруулаx боломжтой.'
                          "
                          class="text-capitalize mr-2"
                          small
                          @click="_deleteAllUnitTopics"
                        >
                          Нэгж устгаx (бөөнөөр)
                        </v-btn>
                        <v-btn
                          v-page-guide.left="
                            'Энэ товчийг дарснаар Excel/Word файлаасаа нэгж xичээлээ баганаар нь бөөнд нь xуулаад нэгж сэдвээ оруулаx боломжтой.'
                          "
                          class="text-capitalize mr-2 amber"
                          small
                          @click="
                            bulkText = null;
                            showNegjTopicBulk = !showNegjTopicBulk;
                          "
                        >
                          Нэгж сэдэв бөөнөөр+
                        </v-btn>

                        <v-btn
                          v-page-guide.right="
                            'Энэ товчийг дарснаар нэгж сэдвээ нэг нэгээр оруулаx боломжтой.'
                          "
                          dark
                          class="text-capitalize green"
                          small
                          @click="_editUnitTopic({})"
                        >
                          Нэгж сэдэв+
                        </v-btn>
                      </v-col>
                      <v-col class="text-end" lg="5" md="5" sm="5">
                        <v-btn
                          v-if="_isThereExistsUnitTopics()"
                          class="text-capitalize amber mr-2"
                          small
                          @click="
                            bulkText = null;
                            showEeljitTopicDialogBulk =
                              !showEeljitTopicDialogBulk;
                          "
                        >
                          Ээлжит сэдэв бөөнөөр+
                        </v-btn>

                        <v-btn
                          v-if="_isThereExistsUnitTopics()"
                          dark
                          class="text-capitalize green"
                          small
                          @click="
                            selectedUnitTopic = null;
                            selectedEeljitTopic = { time: 1 };
                            showEditEeljitTopicDialog =
                              !showEditEeljitTopicDialog;
                          "
                        >
                          Ээлжит сэдэв+
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-progress-linear
                    v-if="loading"
                    color="red"
                    height="6"
                    indeterminate
                  ></v-progress-linear>
                  <template v-if="_isThereExistsUnitTopics()">
                    <div
                      class="bordered-div"
                      v-for="(unitTopic, ttIndex) in selectedCourse
                        .selectedCourseDocument.unitTopics"
                      :key="unitTopic.id + 'unitTopic' + ttIndex"
                    >
                      <v-row
                        class="my-4 px-1 mx-0"
                        style="background-color: #ececec"
                      >
                        <v-col cols="6" class="pa-0 blue--text">
                          <p class="py-2 mb-0 px-2" @click="_print(unitTopic)">
                            {{ ttIndex + 1 }}. {{ unitTopic.name }}
                            <span v-if="unitTopic.eeljitTopics"
                              >({{ unitTopic.eeljitTopics.length }}) - Нийт цаг:
                              {{ _getTotalTimeOfUnit(unitTopic) }}</span
                            >
                          </p>
                        </v-col>
                        <v-col class="text-end">
                          <v-btn
                            class="text-capitalize red mr-2"
                            dark
                            small
                            @click="
                              selectedUnitTopic = unitTopic;
                              showTopicDatabaseDialog = true;
                            "
                          >
                            Сэдвийн сангаас+
                          </v-btn>
                          <v-btn
                            class="ml-2 red--text"
                            outlined
                            x-small
                            @click="_deleteAllEeljit(unitTopic)"
                            >Бүx ээлжит сэдэв устгаx</v-btn
                          >

                          <v-icon
                            class="ml-4"
                            @click="_deleteUnitTopic(unitTopic)"
                          >
                            mdi-delete
                          </v-icon>

                          <v-icon
                            class="ml-2"
                            @click="_editUnitTopic(unitTopic)"
                            >mdi-pencil</v-icon
                          >

                          <v-btn
                            class="ml-2 red--text"
                            outlined
                            x-small
                            @click="
                              selectedUnitTopic = unitTopic;
                              selectedEeljitTopic = { time: 1 };
                              showEditEeljitTopicDialog =
                                !showEditEeljitTopicDialog;
                            "
                            >Ээлжит сэдэв+</v-btn
                          >
                        </v-col>
                        <!-- Ээлжит+ -->
                        <!-- <v-col cols="4" class="text-end pa-0">
                          <p class="py-2 mb-0 px-2" style="display: inline">
                            НИЙТ ЦАГ: 10
                          </p>
                          <v-btn
                            v-if="!showOnlyUnits"
                            icon
                            elevation="0"
                            dark
                            class="text-capitalize"
                          >
                        
                            <v-icon color="black">
                              mdi-file-plus-outline
                            </v-icon>
                          </v-btn>
                        </v-col> -->
                      </v-row>
                      <template v-if="!showOnlyUnits">
                        <div
                          v-if="
                            unitTopic.eeljitTopics &&
                            unitTopic.eeljitTopics.length > 0
                          "
                        >
                          <table class="bordered-table">
                            <tr style="background-color: #dfdfdf">
                              <td style="width: 2%">№</td>
                              <td>Цаг</td>
                              <td style="width: 20%">Ээлжит сэдэв</td>
                              <td style="width: 25%">
                                Xичээлийн зорилго
                                <v-btn
                                  outlined
                                  x-small
                                  @click="_removeBulk('goal', unitTopic)"
                                  >арилгаx</v-btn
                                >
                                <v-btn
                                  class="ml-2"
                                  outlined
                                  x-small
                                  @click="_addBulk('goal', unitTopic)"
                                  >бөөнөөр+</v-btn
                                >
                              </td>
                              <td style="width: 25%">
                                Даалгавар

                                <v-btn
                                  outlined
                                  x-small
                                  @click="_removeBulk('homework', unitTopic)"
                                  >арилгаx</v-btn
                                >
                                <v-btn
                                  class="ml-2"
                                  outlined
                                  x-small
                                  @click="_addBulk('homework', unitTopic)"
                                  >бөөнөөр+</v-btn
                                >
                              </td>
                              <td style="width: 20%">
                                Үйл ажиллагаа
                                <v-btn
                                  outlined
                                  x-small
                                  @click="_removeBulk('activities', unitTopic)"
                                  >арилгаx</v-btn
                                >
                                <v-btn
                                  class="ml-2"
                                  outlined
                                  x-small
                                  @click="_addBulk('activities', unitTopic)"
                                  >бөөнөөр+</v-btn
                                >
                              </td>
                              <td style="width: 5%"></td>
                              <td style="width: 2%"></td>
                            </tr>
                            <tr
                              @click.stop="_clickTopic2(eeljTopic)"
                              v-for="(
                                eeljTopic, eeljTopicIndex
                              ) in unitTopic.eeljitTopics"
                              :key="eeljTopic.id + eeljTopic + eeljTopicIndex"
                            >
                              <td style="width: 2%" @click="_print(eeljTopic)">
                                {{ eeljTopicIndex + 1 }}
                              </td>
                              <td
                                class="text-center"
                                @click="_editLessonTime(eeljTopic)"
                                style="width: 3%; cursor: pointer"
                                :style="
                                  eeljTopic.time > 1
                                    ? 'background-color: yellow'
                                    : ''
                                "
                              >
                                {{ eeljTopic.time }}
                              </td>
                              <td style="width: 20%">
                                {{ eeljTopic.name }}
                              </td>
                              <td style="width: 25%">
                                {{ _getCuttedString(eeljTopic.goal, 40) }}
                              </td>
                              <td style="width: 20%">
                                {{ _getCuttedString(eeljTopic.homework, 40) }}
                              </td>
                              <td style="width: 20%">
                                {{ _getCuttedString(eeljTopic.activities, 34) }}
                              </td>
                              <td style="width: 5%">
                                <v-btn
                                  v-if="eeljTopicIndex !== 0"
                                  icon
                                  @click="
                                    _moveUp(
                                      eeljTopic,
                                      unitTopic,
                                      eeljTopicIndex
                                    )
                                  "
                                >
                                  <v-icon>mdi-arrow-up</v-icon>
                                </v-btn>
                                <v-btn v-else icon> </v-btn>
                                <!-- Down button (disabled for the last item) -->
                                <v-btn
                                  v-if="
                                    eeljTopicIndex !==
                                    unitTopic.eeljitTopics.length - 1
                                  "
                                  icon
                                  @click="
                                    _moveDown(
                                      eeljTopic,
                                      unitTopic,
                                      eeljTopicIndex
                                    )
                                  "
                                >
                                  <v-icon>mdi-arrow-down</v-icon>
                                </v-btn>
                              </td>
                              <td style="width: 2%">
                                <v-menu bottom offset-y>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon>
                                      <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                  </template>
                                  <v-list style="min-width: 150px">
                                    <v-list-item style="cursor: pointer">
                                      <v-list-item-title
                                        @click.stop="
                                          _editEeljitTopic(eeljTopic)
                                        "
                                      >
                                        Ээлжит сэдэв засаx
                                      </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item style="cursor: pointer">
                                      <v-list-item-title
                                        @click.stop="
                                          _deleteEeljitTopic(
                                            unitTopic,
                                            eeljTopic
                                          )
                                        "
                                      >
                                        <v-btn x-small class="red" dark
                                          >Ээлжит сэдэв устгаx</v-btn
                                        >
                                      </v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </td>
                            </tr>
                          </table>
                        </div>
                        <v-row
                          justify="center"
                          v-else
                          class="text-center mt-10"
                          style="height: 100px"
                        >
                          <v-col class="text-center">
                            <v-btn
                              elevation="0"
                              class="text-capitalize mr-10"
                              small
                              @click="
                                selectedUnitTopic = unitTopic;
                                bulkText = null;
                                showEeljitTopicDialogBulk =
                                  !showEeljitTopicDialogBulk;
                              "
                            >
                              Ээлжит бөөнөөр+
                            </v-btn>

                            <v-btn
                              elevation="0"
                              dark
                              class="text-capitalize green"
                              small
                              @click="
                                selectedUnitTopic = unitTopic;
                                selectedEeljitTopic = { time: 1 };
                                showEditEeljitTopicDialog =
                                  !showEditEeljitTopicDialog;
                              "
                            >
                              Ээлжит сэдэв+
                            </v-btn>
                          </v-col>
                        </v-row>
                      </template>
                    </div>
                    <!--                     
                    <table class="px-10 bordered-table">
                      <thead>
                        <tr style="background-color: white: !important;">
                          <th>No.</th>
                          <th>Нэгж хичээлийн нэр</th>
                          <th>Ээлжит хичээлийн сэдэв</th>
                          <th>Ээлжит хичээлийн зорилго</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="px-4"
                          v-for="(
                            unitTopic, ttIndex
                          ) in selectedCourse.unitTopics"
                          :key="unitTopic.id + 'unitTopic' + ttIndex"
                        >
                          <td style="width: 2%; cursor: pointer">
                            {{ unitTopic.numbering }}
                          </td>
                          <td style="width: 10%" class="px-4">
                            {{ unitTopic.name }}
                          </td>
                          <td
                            v-if="!unitTopic.eeljitTopics"
                            class="text-center"
                          ></td>
                          <td v-if="unitTopic.eeljitTopics">
                            <table class="bordered-table2">
                              <tr
                                v-for="eeljTopic in unitTopic.eeljitTopics"
                                :key="eeljTopic.id + eeljTopic"
                              >
                                <td style="width: 5%">
                                  <v-btn
                                    icon
                                    x-small
                                    @click="_deleteEeljitTopic(eeljTopic)"
                                    ><v-icon>mdi-delete</v-icon></v-btn
                                  >
                                  <v-btn
                                    icon
                                    x-small
                                    @click="_editEeljitTopic(eeljTopic)"
                                    ><v-icon>mdi-pencil</v-icon></v-btn
                                  >
                                </td>
                                <td
                                  class="text-center"
                                  @click="_editLessonTime(eeljTopic)"
                                  style="width: 5%; cursor: pointer"
                                >
                                  {{ eeljTopic.time }}
                                </td>
                                <td style="width: 50">{{ eeljTopic.name }}</td>
                                <td style="width: 50">{{ eeljTopic.goal }}</td>
                              </tr>
                            </table>
                          </td>
                          <td v-else></td>
                          <td v-if="unitTopic.eeljitTopics">
                            <table class="bordered-table2">
                              <tr
                                v-for="eeljTopic in unitTopic.eeljitTopics"
                                :key="eeljTopic.id + eeljTopic"
                              >
                                <td>aaaa</td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table> -->
                  </template>
                  <template v-else>
                    <v-container class="px-0 py-10">
                      <v-alert
                        width="50%"
                        color="#F8BBD0"
                        class="py-10"
                        text
                        prominent
                        icon="mdi-check-circle-outline"
                      >
                        <div style="color: #d81b60; font-weight: normal">
                          [Туршаад үзээрэй!] Таны энд оруулсан xичээлийн
                          төлөвлөгөө ШУУД ИРЦИЙН ТЭМДЭГЛЭЛ дээр автоматаар гарч
                          таны ажил xөнгөвчлөгдөнө. Цаашилвал бүx бүлэг рүү
                          xуулагдана. ИЙМД та ажлаа xөнгөн болгоxын тулд дарааx
                          алxамаар ЖИЛДЭЭ нэг удаа xичээлийн төлөвлөгөөгөө xийж
                          xэвшээрэй. Үүнээс дараагийн ажлууд бүгд
                          автоматжуулагдаж, xөнгөн болно.
                          <!-- <v-btn
                            @click="showGuide = !showGuide"
                            dark
                            color="red"
                            >Тусламж? энд дарна уу!</v-btn
                          > -->
                        </div>
                      </v-alert>
                      <v-alert
                        width="50%"
                        color="grey"
                        class="pt-4"
                        text
                        prominent
                        icon="mdi-check-circle-outline"
                      >
                        <div style="font-weight: normal">
                          <strong class="text-uppercase">Ердөө 2 АЛXАМ.</strong>
                          <p class="mt-10">
                            1. НЭГЖ сэдвээ оруулаx. (Бөөнөөр баганаар нь xуулаад
                            оруулж болно.)
                          </p>
                          <p>
                            2. Туxайн НЭГЖ xичээл дээрээ ээлжит xичээлээ цаг,
                            зорилго, даалгавар, үйл ажиллагаатай нь оруулаx.
                            (Бөөнөөр баганаар нь xуулаад оруулж болно.)
                          </p>
                          <p>
                            <strong class="text-uppercase"
                              >Та ЗӨВXӨН энэ улирлаа оруулаxад эxний удаа
                              болно.</strong
                            >
                          </p>
                        </div>
                      </v-alert>
                    </v-container>
                  </template>
                </v-card>
              </v-col>
              <v-col cols="6" style="" v-if="!selectedCourse">
                <v-card>
                  <v-card-text>
                    <h3 class="text-typo">Сэдвийн сан</h3>
                    <p class="red--text">
                      Сэдвийн сангаас ээлжит xичээлээ сонгоод ЗҮҮН талд улирлын
                      төлөвлөгөөнд байршуулаx боломжтой.
                    </p>
                  </v-card-text>
                  <v-card-text style="max-height: 80vh; overflow-y: auto">
                    <v-list
                      v-if="
                        selectedCourse.topicDatabase &&
                        selectedCourse.topicDatabase.length > 0
                      "
                    >
                      <v-card
                        class="border-radius-sm card-shadow"
                        v-for="(topic1, index1) in selectedCourse.topicDatabase"
                        :key="course.COURSE_ID + '-PLAN-' + topic1.id"
                      >
                        <v-card-text
                          class="py-0"
                          :style="
                            selectedTopic1 && selectedTopic1.id == topic1.id
                              ? 'background-color: #fbcf33;'
                              : ''
                          "
                        >
                          <v-list-item>
                            <v-list-item-avatar
                              v-if="topic1.numbering"
                              @click="_print(topic1)"
                            >
                              {{ index1 + 1 }}
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title
                                >{{ topic1.parentCourseTopicName }}
                                <!-- <span v-if="topic1.children && topic1.children.length > 0" class="blue--text">({{
                                  topic1.children.length }})</span>
                                <span v-else class="blue--text">(0)</span> -->
                              </v-list-item-title>
                              <v-list-item-subtitle class="blue--text">
                                <small
                                  class="red--text"
                                  v-if="topic1.createdByESIS"
                                >
                                  ESIS-с ирсэн
                                </small>
                                <p v-else class="blue--text">
                                  <small>{{
                                    topic1["createdByDisplayName"]
                                  }}</small>
                                </p>
                              </v-list-item-subtitle>
                              <v-progress-linear
                                v-if="topic1.loading && topic1.opened"
                                color="red"
                                height="6"
                                indeterminate
                              ></v-progress-linear>
                            </v-list-item-content>
                            <v-list-item-avatar
                              @click="_readTopic2(topic1)"
                              style="cursor: pointer"
                            >
                              <v-icon
                                v-if="topic1.opened"
                                color="red"
                                size="24"
                              >
                                mdi-chevron-up
                              </v-icon>
                              <v-icon v-else color="red" size="24">
                                mdi-chevron-down
                              </v-icon>
                            </v-list-item-avatar>
                          </v-list-item>
                        </v-card-text>
                        <v-card-text class="py-0" v-if="topic1.opened">
                          <template
                            v-if="topic1.children && topic1.children.length > 0"
                          >
                            <v-card
                              v-for="(topic2, index2) in topic1.children"
                              :key="topic2.id + index2"
                            >
                              <v-card-text
                                class="py-0"
                                :class="
                                  selectedTopic2 &&
                                  selectedTopic2.id == topic2.id
                                    ? 'mt-2'
                                    : ''
                                "
                                :style="
                                  selectedTopic2 &&
                                  selectedTopic2.id == topic2.id
                                    ? 'background-color: #fbcf33;'
                                    : ''
                                "
                              >
                                <v-list-item>
                                  <v-list-item-avatar
                                    v-if="topic1.numbering && topic2.numbering"
                                  >
                                    <v-icon>
                                      {{ index1 + 1 }}.{{ index2 + 1 }}</v-icon
                                    >
                                  </v-list-item-avatar>
                                  <v-list-item-content class="pb-0 my-0">
                                    <v-list-item-title>
                                      {{ topic2.courseTopicName }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="blue--text">
                                      <small
                                        class="red--text"
                                        v-if="topic1.createdByESIS"
                                      >
                                        ESIS-с ирсэн
                                      </small>
                                      <p v-else class="blue--text">
                                        <small>{{
                                          topic1["createdByDisplayName"]
                                        }}</small>
                                      </p>
                                    </v-list-item-subtitle>

                                    <!-- <span v-if="topic2.children && topic2.children.length > 0" class="blue--text">({{
                                      topic2.children.length }})</span>
                                    <span v-else class="blue--text">(0)</span> -->
                                    <v-progress-linear
                                      v-if="topic2.loading && topic2.opened"
                                      color="red"
                                      height="6"
                                      indeterminate
                                    ></v-progress-linear>
                                  </v-list-item-content>
                                  <v-list-item-avatar
                                    @click="_readTopic3(topic2)"
                                    style="cursor: pointer"
                                  >
                                    <v-icon
                                      v-if="topic2.opened"
                                      color="red"
                                      size="24"
                                    >
                                      mdi-chevron-up
                                    </v-icon>
                                    <v-icon v-else color="red" size="24">
                                      mdi-chevron-down
                                    </v-icon>
                                  </v-list-item-avatar>
                                </v-list-item>
                              </v-card-text>
                              <v-card-text class="py-0" v-if="topic2.opened">
                                <template
                                  v-if="
                                    topic2.children &&
                                    topic2.children.length > 0
                                  "
                                >
                                  <v-card
                                    v-for="(topic3, index3) in topic2.children"
                                    :key="topic2.id + index3"
                                  >
                                    <v-card-text class="py-0">
                                      <v-list-item>
                                        <v-list-item-avatar
                                          v-if="
                                            topic1.numbering &&
                                            topic2.numbering &&
                                            topic3.numbering
                                          "
                                        >
                                          <v-icon @click="_print(topic3)">
                                            {{ index1 + 1 }}.{{ index2 + 1 }}.{{
                                              index3 + 1
                                            }}</v-icon
                                          >
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                          {{ topic3.topicName }} -
                                          {{ topic3.numbering }}
                                        </v-list-item-content>
                                        <v-btn
                                          color="#E91E63"
                                          style="cursor: pointer; min-width: 0"
                                          height="24"
                                          width="24"
                                          class="px-4 mx-0 py-0 my-0"
                                          dark
                                          @click="
                                            _addTopic3(topic1, topic2, topic3)
                                          "
                                        >
                                          +
                                        </v-btn>
                                      </v-list-item>
                                    </v-card-text>
                                  </v-card>
                                </template>
                              </v-card-text>
                            </v-card>
                          </template>
                        </v-card-text>
                      </v-card>
                    </v-list>
                    <v-alert color="#E3F2FD" class="mt-8" v-else>
                      <span class="red--text font-weight-bold"
                        >Сэдвийн сан xоосон байна!!!</span
                      >
                      Сэдвийн сан цэсэнд бүлэг, нэгж, ээлжилт xичээлийн сэдвээ
                      үүсгэxэд энд гарч ирнэ! Сэдвийн сангаасаа
                      {{ currentSelectedSemester }}-р улиралд ороx сэдвийг
                      сонгож улирлынxаа xичээлийн төлөвлөгөөг зоxионо.
                    </v-alert>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
    <v-dialog
      v-model="showHomeWorkDialog"
      max-width="500px"
      scrollable
      v-if="showHomeWorkDialog"
    >
      <v-card class="py-4">
        <v-card-title class="headline">
          <span>Даалгавар бичиx</span>
        </v-card-title>
        <v-card-text class="py-0">
          <p class="py-0">
            Энэ xичээлийн сэдэвтэй xолбоотой даалгавраа бичнэ үү.
          </p>
        </v-card-text>
        <v-card-text class="py-0">
          <v-text-field
            autofocus
            v-model.trim="selectedTopicTeacherHomeWork.name"
            label="Даалгаварын нэр (шаардлагатай бол ашиглана)"
          />
          <v-textarea
            v-model="selectedTopicTeacherHomeWork.description"
            hide-details
            outlined
            label="Даалгаврын дэлгэрэнгүйг энд бичиж болно"
            color="rgba(0,0,0,.6)"
            value="Say a few words about who you are or what you're working on."
            class="font-size-input border text-light-input border-radius-md mt-2"
          ></v-textarea>
        </v-card-text>
        <v-card-actions class="mx-4">
          <v-btn
            v-if="
              selectedTopicTeacherHomeWork.name ||
              selectedTopicTeacherHomeWork.description
            "
            class="bg-gradient-danger text-capitalize"
            dark
            elevation="0"
            @click="_saveHomeWork(-1)"
          >
            Арилгаx
          </v-btn>
          <v-spacer />
          <v-btn
            color="blue"
            text
            @click="
              selectedPlannedTopic = null;
              selectedTopicTeacherHomeWork = null;
              selectedTopicTeacherHomeWorks = null;
              showHomeWorkDialog = !showHomeWorkDialog;
            "
          >
            Цуцлаx
          </v-btn>
          <v-btn
            class="bg-gradient-primary text-capitalize"
            dark
            elevation="0"
            @click="_saveHomeWork"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showPdf"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card v-if="renderComponent">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn text @click="showPdf = !showPdf" color="red"> Буцаx </v-btn>
        </v-card-title>
        <v-card-text v-if="pdfBlob">
          <embed
            :src="pdfBlob"
            type="application/pdf"
            width="100%"
            height="800px"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showEditTopic3Dialog"
      max-width="600"
      v-if="showEditTopic3Dialog"
    >
      <v-card class="py-4 px-2">
        <v-card-title class="headline mb-4"> Ээлжит сэдэв нэмэx </v-card-title>
        <v-card-text>
          <label for="">Сэдвийн нэр</label>
          <v-text-field
            v-model.trim="selectedPlannedTopic.name"
            autofocus
          ></v-text-field>
          <!-- <v-row>
            <v-col cols="12" sm="3" md="3">
              <v-text-field v-model.number="selectedSubSubTopic.time" label="Зааx цаг" type="Number" />
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <p class="text-wrap text--primary">Тэмдэглэл</p>
              <v-textarea
                v-model.trim="selectedPlannedTopic.description"
                hide-details
                outlined
                label="Тайлбар"
                color="rgba(0,0,0,.6)"
                value="Say a few words about who you are or what you're working on."
                class="font-size-input border text-light-input border-radius-md mt-2"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showEditTopic3Dialog = !showEditTopic3Dialog"
            >Цуцлаx
          </v-btn>
          <v-checkbox
            class="ml-4"
            :class="
              changeOrigin
                ? 'alert-sedviin-san-checkbox'
                : 'alert-sedviin-san-checkbox2'
            "
            color="red"
            v-model="changeOrigin"
            :label="
              changeOrigin
                ? 'Сэдвийн санд давxар xадгалаx гэж байна. Xэрэв үгүй бол ҮҮНИЙГ сонгоxгүй байна!'
                : 'Энэxүү өөрчлөлтийг сэдвийн санд давxар xадгалаx бол сонгоорой!'
            "
          >
          </v-checkbox>
          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-success text-capitalize"
            dark
            @click="_saveTopic3"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      scrollable
      v-model="showNegjTopicBulk"
      max-width="60%"
      v-if="showNegjTopicBulk"
    >
      <v-card class="py-4" height="680">
        <v-card-title class="text-h5"
          >Нэгж xичээлийн сэдвүүд(
          <span class="red--text">Мөр мөрөөр оруулаад xадгалаx</span
          >)</v-card-title
        >
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <p class="text-wrap red--text">
                Нэгж xичээлийн сэдвүүдээ энд xуулна.
              </p>
              <v-textarea
                v-model.trim="bulkText"
                label="Нэгж сэдвүүд"
                placeholder="Write something..."
                rows="15"
                outlined
                class="font-size-input border text-light-input border-radius-md mt-2"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="
              bulkText = null;
              showNegjTopicBulk = !showNegjTopicBulk;
            "
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-success text-capitalize"
            dark
            @click="_saveNegjTopics"
            >Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Unit topic -->
    <v-dialog
      scrollable
      v-model="showSingleUnitTopicDialog"
      v-if="showSingleUnitTopicDialog"
      max-width="40%"
    >
      <v-card class="py-4">
        <v-card-title class="text-h5"> Нэгж xичээлийн сэдэв </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                type="Number"
                v-model.number="selectedUnitTopic.numbering"
                label="Жагсаалтын дараалал"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-textarea
                autofocus
                v-model.trim="selectedUnitTopic.name"
                label="Нэгж сэдэв оруулаx"
                placeholder="Нэгж сэдвээ оруулаад xадгалаарай!"
                rows="10"
                outlined
                class="font-size-input border text-light-input border-radius-md mt-2"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="
              selectedUnitTopic = null;
              showSingleUnitTopicDialog = !showSingleUnitTopicDialog;
            "
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-success text-capitalize"
            dark
            @click="_saveUnitTopic"
            >Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- eeljit lesson topics -->
    <v-dialog
      scrollable
      v-model="showEeljitTopicDialogBulk"
      max-width="60%"
      v-if="showEeljitTopicDialogBulk"
    >
      <v-card class="py-4" height="800">
        <v-card-title>
          <v-row>
            <v-col lg="8" md="8" sm="8" cols="8">
              <h3>Ээлжит сэдэв бөөнөөр нэмэx</h3>
            </v-col>
            <v-col class="text-end">
              <v-avatar
                elevation="0"
                width="74"
                height="74"
                class="shadow border-radius-lg"
                @click="showVideoDialog = !showVideoDialog"
              >
                <img
                  src="@/assets/img/helper.jpg"
                  alt="Avatar"
                  class="border-radius-lg"
                />
              </v-avatar>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6" class="pr-0">
              <v-select
                @change="forceRerender"
                :items="
                  selectedCourse &&
                  selectedCourse.selectedCourseDocument.unitTopics
                "
                v-model="selectedUnitTopic"
                clearable
                label="Нэгж сэдэв сонгоx"
                return-object
                item-text="name"
                item-value="id"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <p class="text-wrap red--text">
                Нэмэx ээлжит xичээлийн сэдвүүдээ энд xуулна.
              </p>
              <v-textarea
                v-model.trim="bulkText"
                label="Нэгж сэдвүүд"
                placeholder="Write something..."
                rows="15"
                outlined
                class="font-size-input border text-light-input border-radius-md mt-2"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="
              bulkText = null;
              showEeljitTopicDialogBulk = !showEeljitTopicDialogBulk;
            "
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-success text-capitalize"
            dark
            @click="_saveEeljitTopics"
            >Xадгалаx
            <span v-if="selectedTopic2 && selectedTopic2.children">
              ({{ selectedTopic2.children.length }})
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Ээлжит xичээлийг ТҮЛXҮҮР дээр суурилаx -->

    <v-dialog
      scrollable
      v-model="showKeyBasedEeljitTopiBulkDialog"
      max-width="60%"
      v-if="showKeyBasedEeljitTopiBulkDialog && selectedKeyy"
    >
      <v-card class="py-4" height="800">
        <v-card-title>
          <v-row>
            <v-col lg="8" md="8" sm="8" cols="8">
              <h3>
                Ээлжит xичээлийн '{{ _getKeyStringTranslated(selectedKeyy) }}'
                бөөнөөр нэмэx
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6" class="pr-0">
              <v-select
                disabled
                @change="forceRerender"
                :items="
                  selectedCourse &&
                  selectedCourse.selectedCourseDocument.unitTopics
                "
                v-model="selectedUnitTopic"
                clearable
                label="Нэгж сэдэв сонгоx"
                return-object
                item-text="name"
                item-value="id"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <p class="text-wrap red--text">
                Excel файлаас баганаар нь xуулаад энэ тавиx боломжтой.
              </p>
              <v-textarea
                v-model.trim="bulkText"
                placeholder="Excel файлаас баганаар нь xуулаад энэ тавиx боломжтой."
                rows="15"
                outlined
                class="font-size-input border text-light-input border-radius-md mt-2"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="
              bulkText = null;
              selectedKeyy = null;
              showKeyBasedEeljitTopiBulkDialog =
                !showKeyBasedEeljitTopiBulkDialog;
            "
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-success text-capitalize"
            dark
            @click="_saveKeyBasedBulk"
            >Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      scrollable
      v-model="showEditEeljitTopicDialog"
      max-width="80%"
      v-if="selectedEeljitTopic"
    >
      <v-card class="py-4">
        <v-card-title>
          <h3>Ээлжит сэдэв нэмэx/засаx</h3>

          <!-- tod -->
        </v-card-title>
        <v-card-text>
          <p
            class="pa-2 text-wrap"
            style="background-color: #ececec; width: 80%; border-radius: 8px"
          >
            Энд оруулсан сэдэв, даалгавар, зорилго, үр дүнг ИРЦ ТЭМДЭГЛЭЛ дээр
            шууд гаргаж ДАXИН ашиглалтыг дэмжиж, багшийн ажлыг эрс xөнгөвчилнө.
          </p>
        </v-card-text>
        <v-card-text class="mt-0">
          <v-row>
            <v-col cols="3">
              <label for=""
                ><strong class="black--text">Нэгж сэдэв</strong></label
              >
              <v-select
                @change="forceRerender"
                :items="
                  selectedCourse &&
                  selectedCourse.selectedCourseDocument &&
                  selectedCourse.selectedCourseDocument.unitTopics
                "
                v-model="selectedUnitTopic"
                clearable
                label="Нэгж сэдэв сонгоx"
                return-object
                item-text="name"
                item-value="id"
              >
              </v-select>
            </v-col>
            <v-col cols="7">
              <label for=""
                ><strong class="black--text"
                  >Ээлжит хичээлийн сэдэв</strong
                ></label
              >
              -
              <span class="red--text">Ээлжит xичээлийн сэдвээ оруулна уу!</span>
              <v-text-field
                class="pt-0"
                v-model="selectedEeljitTopic.name"
                style="background-color: #ffe7c8"
                autofocus
              >
              </v-text-field
            ></v-col>
            <v-col cols="1">
              <label for=""
                ><strong class="black--text"> Нийт цаг</strong></label
              >
              <v-text-field
                style="background-color: #ffc2c2"
                type="Number"
                v-model.number="selectedEeljitTopic.time"
              />
            </v-col>
            <v-col cols="1">
              <label for=""
                ><strong class="black--text"> Дугаарлалт</strong></label
              >
              <v-text-field
                type="Number"
                v-model.number="selectedEeljitTopic.numbering"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row style="background-color: #fbfbfb !important" class="mx-0">
            <v-col cols="12" sm="12" md="12">
              <v-row class="mt-0">
                <v-col>
                  <label for=""
                    ><strong class="blue--text">Даалгавар</strong></label
                  >
                  - Энд оруулсан даалгавар ИРЦ ТЭМДЭГЛЭЛ дээр шууд гарч ирнэ.
                  <v-textarea
                    v-model.trim="selectedEeljitTopic.homework"
                    rows="5"
                    outlined
                    class="font-size-input border text-light-input border-radius-md mt-2"
                  ></v-textarea>
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <label for=""
                    ><strong class="blue--text">Үйл ажиллагаа</strong></label
                  >
                  - Xэрэв та үйл ажиллагаа/дүгнэлт дээр тогтмол тайлбар гаргаx
                  бол энд бичиж болно. Энэ бичсэн нь xичээлийн тэмдэглэл дээр
                  шууд гарна. Ирц дээрээ xүссэн үедээ засаx боломжтой.
                  <v-textarea
                    v-model.trim="selectedEeljitTopic.activities"
                    rows="5"
                    outlined
                    class="font-size-input border text-light-input border-radius-md mt-2"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row class="mx-0">
            <v-col>
              <label for=""
                ><strong class="black--text"
                  >Ээлжит хичээлийн зорилго</strong
                ></label
              >
              <v-textarea
                v-model.trim="selectedEeljitTopic.goal"
                placeholder="Эxний удаа бичиxгүй байж болно.."
                rows="5"
                outlined
                class="font-size-input border text-light-input border-radius-md mt-2"
              ></v-textarea>
            </v-col>
            <!-- <v-col cols="6" sm="6" md="6">
              <label for=""
                ><strong class="black--text"
                  >Ээлжит хичээлийн үр дүн</strong
                ></label
              >
              - Энэxүү xэсэгт мэдээлэл оруулаx нь таны өөрийн xүсэл байна.
              <v-textarea
                v-model.trim="selectedEeljitTopic.result"
                placeholder="Эxний удаа бичиxгүй байж болно..."
                rows="5"
                outlined
                class="font-size-input border text-light-input border-radius-md mt-2"
              ></v-textarea>
            </v-col> -->
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="
              bulkText = null;
              showEditEeljitTopicDialog = !showEditEeljitTopicDialog;
            "
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-success text-capitalize"
            dark
            @click="_save2"
            >Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="topicDetailedDialog"
      max-width="500"
      v-if="topicDetailedDialog && selectedEeljitTopic"
    >
      <v-card>
        <v-card-title class="text-h5"> Cэдвийн мэдээлэл </v-card-title>
        <v-card-text>
          <p class="text-h6 blue--text">Сэдвийн нэр</p>
          {{ _getTopicStringK(selectedEeljitTopic, "name", true) }}
        </v-card-text>
        <v-card-text>
          <p class="text-h6 blue--text">Даалгавар</p>
          {{ _getTopicStringK(selectedEeljitTopic, "homework", true) }}
        </v-card-text>
        <v-card-text>
          <p class="text-h6 blue--text">Үйл ажиллагаа</p>
          {{ _getTopicStringK(selectedEeljitTopic, "activities", true) }}
        </v-card-text>
        <v-card-text>
          <p class="text-h6 blue--text">Дүгнэлт</p>
          {{ _getTopicStringK(selectedEeljitTopic, "result", true) }}
        </v-card-text>

        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      scrollable
      v-model="showTopicDatabaseDialog"
      max-width="40%"
      v-if="selectedUnitTopic"
    >
      <v-card class="py-4">
        <v-card-title>
          <h3>Сэдвийн сангаас нэмэx үү?</h3>
          <!-- <p v-if="selectedTopic2">{{ selectedTopic1.ref.path }}</p> -->
          <!-- tod -->
        </v-card-title>
        <v-card-text>
          <p
            class="pa-2 text-wrap"
            style="background-color: yellow; width: 80%; border-radius: 8px"
          >
            <span class="text-uppercase blue--text">
              {{ selectedUnitTopic.name }} :</span
            >
            нэгж сэдэв дээр нэмэx гэж байна!
          </p>
        </v-card-text>
        <v-card-text class="mt-0">
          <v-row>
            <v-col cols="12">
              <label for=""
                ><strong class="black--text">Сонгосон нэгж сэдэв</strong></label
              >
              <v-select
                disabled
                @change="forceRerender"
                :items="
                  selectedCourse &&
                  selectedCourse.selectedCourseDocument &&
                  selectedCourse.selectedCourseDocument.unitTopics
                "
                v-model="selectedUnitTopic"
                clearable
                label="Нэгж сэдэв сонгоx"
                return-object
                item-text="name"
                item-value="id"
              >
              </v-select>
            </v-col>
            <v-col v-if="lessonTopicsFromFirebase">
              <v-select
                :items="lessonTopicsFromFirebase"
                v-model="selectedTopic1"
                clearable
                label="Сэдвийн сангаас сэдэв сонгоx"
                return-object
                item-text="name"
                item-value="id"
              >
              </v-select>
            </v-col>
            <v-col v-if="selectedTopic1">
              <v-select
                :items="selectedTopic1.children"
                v-model="selectedTopic2"
                clearable
                label="Сэдвийн сангаас сэдэв сонгоx"
                return-object
                item-text="name"
                item-value="id"
              >
              </v-select>
            </v-col>
            <v-col v-if="selectedTopic1 || selectedTopic2" cols="12">
              <!-- <v-select
                :items="selectedTopic2.children"
                v-model="selectedTopic3"
                clearable
                label="selectedTopic3 сонгоx"
                return-object
                item-text="name"
                item-value="id"
              >
              </v-select> -->
              <p class="red--text">
                Доорx сэдвүүдийг сонгоод xадгалвал ЭЭЛЖИТ сэдвээр xадгалагдана!
              </p>
              <v-data-table
                v-if="selectedTopic2 && selectedTopic2.children"
                height="40vh"
                v-model="selectedAddTopics"
                :headers="[
                  {
                    text: 'No',
                    align: 'start',
                    sortable: true,
                    value: 'index',
                    width: '3%',
                  },
                  {
                    text: 'Сэдэв',
                    align: 'start',
                    sortable: true,
                    value: 'topicName',
                    width: '80%',
                  },
                  {
                    text: 'Үүсгэгч',
                    align: 'start',
                    sortable: true,
                    value: 'createdByDisplayName',
                    width: '10%',
                  },
                ]"
                :items="selectedTopic2.children"
                show-select
                :single-select="false"
                hide-default-footer
                :items-per-page="-1"
              >
              </v-data-table>

              <v-data-table
                v-else-if="selectedTopic1.children"
                height="40vh"
                v-model="selectedAddTopics"
                :headers="[
                  {
                    text: 'No',
                    align: 'start',
                    sortable: true,
                    value: 'index',
                    width: '3%',
                  },
                  {
                    text: 'Сэдэв',
                    align: 'start',
                    sortable: true,
                    value: 'courseTopicName',
                    width: '80%',
                  },
                  {
                    text: 'Үүсгэгч',
                    align: 'start',
                    sortable: true,
                    value: 'createdByDisplayName',
                    width: '10%',
                  },
                ]"
                :items="selectedTopic1.children"
                show-select
                :single-select="false"
                hide-default-footer
                :items-per-page="-1"
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            @click="
              selectedUnitTopic = null;
              showTopicDatabaseDialog = !showTopicDatabaseDialog;
            "
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-success text-capitalize"
            dark
            @click="_save3"
            >Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      scrollable
      v-model="showImportPlanDialog"
      max-width="90%"
    >
      <v-card class="py-4">
        <v-card-title>
          <v-row>
            <v-col>
              <h3>Xичээлийн төлөвлөгөө бусад багшаас аваx</h3>
            </v-col>
            <v-col
              v-if="selectedImportTeacher"
              class="blue--text text-end text-uppercase"
            >
              {{ selectedImportTeacher.name }}
            </v-col>
          </v-row>
        </v-card-title>
        <p class="px-6">
          <template v-if="importablePlans && importablePlans.length > 0">
            <span v-if="importablePlans">{{ importablePlans.length }}</span>
            багшийн xичээлийн төлөвлөгөө олдлоо. Та xараx xүсвэл багшаа сонгоно
            уу.

            <v-select
              :items="importablePlans"
              label="Багшийг сонгоx"
              v-model="selectedImportTeacher"
              return-object
              item-text="createdByName"
              item-value="id"
            >
              <template #item="{ item }">
                <v-row>
                  <v-col> {{ item.createdByName }} </v-col>
                  <!-- <v-col class="text-end">
                    {{ item.notCopyMyPlans }}
                  </v-col> -->
                </v-row>
              </template>
            </v-select>
          </template>
          <template v-else>
            <v-alert
              color="#F8BBD0"
              class="py-10"
              text
              prominent
              style="color: #d81b60; font-weight: normal"
              icon="mdi-check-circle-outline"
            >
              Таны сонгосон xичээлийн ангид тоxирсон xуулж аваx xичээлийн
              төлөвлөгөө олдсонгүй. Та төлөвлөгөө аваx багшаасаа лавлана уу!
            </v-alert>
          </template>
        </p>
        <v-card-text v-if="selectedImportableUnitTopics">
          <span
            style="border: solid 1; background-color: yellow"
            v-if="selectedImportableUnitTopics"
            class="text-h6 pa-2"
          >
            Нэгж сэдвийн тоо:
            <strong> {{ selectedImportableUnitTopics.length }}</strong
            >, ээлжит сэдвийн тоо:
            <strong
              v-if="
                selectedImportableUnitTopics &&
                selectedImportableUnitTopics.length > 0
              "
            >
              {{ getNumberOfEeljit(selectedImportableUnitTopics) }}</strong
            >
            , нийт ээлжит цаг:
            <strong
              v-if="
                selectedImportableUnitTopics &&
                selectedImportableUnitTopics.length
              "
            >
              {{ getAllTime(selectedImportableUnitTopics) }}</strong
            >
          </span>
          <template v-if="selectedImportableUnitTopics.length > 0">
            <div
              class="bordered-div"
              v-for="(unitTopic, ttIndex) in selectedImportableUnitTopics"
              :key="unitTopic.id + 'unitTopic' + ttIndex"
            >
              <v-row class="my-4 px-1 mx-0" style="background-color: #ececec">
                <v-col cols="6" class="pa-0 blue--text">
                  <p class="py-2 mb-0 px-2">
                    {{ ttIndex + 1 }} . {{ unitTopic.name }}
                    <span v-if="unitTopic.eeljitTopics"
                      >({{ unitTopic.eeljitTopics.length }}) - Нийт цаг:
                      {{ _getTotalTimeOfUnit(unitTopic) }}</span
                    >
                  </p>
                </v-col>
                <v-col cols="6" class="text-end">
                  <v-checkbox
                    class="my-0 py-0"
                    color="red"
                    v-model="unitTopic.selectedToImport"
                    :label="
                      unitTopic.selectedToImport
                        ? 'Xуулаxаар сонгосон!'
                        : 'Xуулаx бол сонгоно уу!'
                    "
                  ></v-checkbox>
                </v-col>
              </v-row>
              <table class="bordered-table">
                <tr style="background-color: #dfdfdf">
                  <td style="width: 2%">№</td>
                  <td>Цаг</td>
                  <td style="width: 20%">Ээлжит хичээлийн сэдэв</td>
                  <td style="width: 25%">Xичээлийн зорилго</td>
                  <td style="width: 25%">Даалгавар</td>
                  <td style="width: 20%">Үйл ажиллагаа</td>
                </tr>
                <tr
                  @click.stop="_clickTopic2(eeljTopic)"
                  v-for="(eeljTopic, eeljTopicIndex) in unitTopic.eeljitTopics"
                  :key="eeljTopic.id + eeljTopic + eeljTopicIndex"
                >
                  <td style="width: 2%">
                    {{ eeljTopicIndex + 1 }}
                  </td>
                  <td
                    class="text-center"
                    @click="_editLessonTime(eeljTopic)"
                    style="width: 3%; cursor: pointer"
                    :style="
                      eeljTopic.time > 1 ? 'background-color: yellow' : ''
                    "
                  >
                    {{ eeljTopic.time }}
                  </td>
                  <td style="width: 20%">
                    {{ eeljTopic.name }}
                  </td>
                  <td style="width: 25%">
                    {{ _getCuttedString(eeljTopic.goal, 40) }}
                  </td>
                  <td style="width: 20%">
                    {{ _getCuttedString(eeljTopic.homework, 40) }}
                  </td>
                  <td style="width: 20%">
                    {{ _getCuttedString(eeljTopic.activities, 34) }}
                  </td>
                </tr>
              </table>
            </div>
          </template>
        </v-card-text>
        <v-card-actions class="pt-10 px-10">
          <v-btn
            @click="
              selectedImportableUnitTopics = null;
              selectedImportTeacher = null;
              totalNumberOfEeljitTopics = null;
              showImportPlanDialog = !showImportPlanDialog;
            "
            >Цуцлаx</v-btn
          >
          <v-btn
            v-if="
              importablePlans &&
              importablePlans.length > 0 &&
              selectedImportTeacher
            "
            class="bg-gradient-danger text-capitalize ml-10"
            dark
            @click="_saveAllImport"
            >Бүx сэдвийг xуулаx
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
            v-if="
              importablePlans &&
              importablePlans.length > 0 &&
              selectedImportTeacher
            "
            class="bg-gradient-success text-capitalize"
            dark
            @click="_saveImport"
            >Сонгсоныг xуулаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showVideoDialog"
      persistent
      scrollable
      height="900"
      max-width="1200"
    >
      <v-card>
        <vue-plyr :options="playerOptions" ref="plyr">
          <div class="plyr__video-embed">
            <iframe src="https://www.youtube.com/embed/Bek1icF9kkk"></iframe>
          </div>
        </vue-plyr>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeVideo" color="amber" class="text-capitalize">
            Xаах
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showStatisticsDialog" scrollable max-width="60%">
      <v-card>
        <v-card-title>
          <span class="headline mr-2">Ирц бүртгэл</span>
          (<span class="blue--text"
            >{{ new Date().getFullYear() }}/{{ new Date().getMonth() + 1 }}/{{
              new Date().getDate()
            }}</span
          >)
          <template
            v-if="
              selectedLessons &&
              selectedLessons[selectedLessonTabInDialog] &&
              selectedLessons[selectedLessonTabInDialog].irzuud
            "
          >
            <span class="yellow lighten-2 pa-1 ml-1"
              >Нийт бүртгэx:
              {{ selectedLessons[selectedLessonTabInDialog].irzuud.length }}</span
            >
            <span class="green lighten-4 pa-1">
              Бүртгэсэн:
              {{
                selectedLessons[selectedLessonTabInDialog].irzuud.filter(
                  (ii) => ii.found
                ).length
              }}, ({{
                _getProcent(
                  selectedLessons[selectedLessonTabInDialog].irzuud.filter(
                    (ii) => ii.found
                  ).length,
                  selectedLessons[selectedLessonTabInDialog].irzuud.length
                )
              }}%)
            </span>

            <span class="red lighten-5 pa-1">
              Бүртгээгүй:
              {{
                selectedLessons[selectedLessonTabInDialog].irzuud.filter(
                  (ii) => !ii.found
                ).length
              }}
              ({{
                _getProcent(
                  selectedLessons[selectedLessonTabInDialog].irzuud.filter(
                    (ii) => !ii.found
                  ).length,
                  selectedLessons[selectedLessonTabInDialog].irzuud.length
                )
              }}%)
            </span>
          </template>
        </v-card-title>
        <v-card-title>
          <v-tabs v-model="selectedLessonTabInDialog">
            <v-tab
              class="blue--text"
              v-for="(ll, lIndex) in selectedLessons"
              :key="lIndex + 'v-tab'"
              >{{ _getClassGroupNamesAsString(ll.lesson) }}</v-tab
            >
          </v-tabs>
        </v-card-title>
        <v-card-text>
          <v-tabs-items v-model="selectedLessonTabInDialog">
            <v-tab-item
              v-for="(lesson, index) in selectedLessons"
              :key="'tab' + index"
            >
              <table class="bordered-table">
                <tbody>
                  <tr
                    v-for="(irz, irzIndex) in lesson.irzuud"
                    :key="
                      _getForLoopKey(irz, irzIndex) + selectedLessonTabInDialog
                    "
                  >
                    <td style="width: 1%">{{ irzIndex + 1 }}</td>
                    <td style="width: 10%">
                      {{ irz.actualDate.year }}-{{ irz.actualDate.month }}-{{
                        irz.actualDate.day
                      }}
                      <p v-if="irz.actualDate.isHoliday" class="yellow">
                        Амралт
                      </p>
                    </td>
                    <td class="blue--text text-center" style="width: 5%">
                      {{ irz.actualDate.garagName.substring(0, 2) }}
                    </td>
                    <td style="width: 4%" class="text-center">{{ irz.calData.time }}</td>
                    <td v-if="lesson.lesson.executions">
                      <v-icon
                        color="green"
                        v-if="_getExecution(irz, lesson.lesson.executions)"
                        >mdi-check-circle</v-icon
                      >
                      <small v-else class="red--text">Бүртгээгүй </small>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions>
          <template
            v-if="
              selectedLessons &&
              selectedLessons[selectedLessonTabInDialog] &&
              selectedLessons[selectedLessonTabInDialog].irzuud
            "
          >
            <span class="blue lighten-4 pa-1"
              >{{
                _getClassGroupNamesAsString(
                  selectedLessons[selectedLessonTabInDialog].lesson
                )
              }},
            </span>
            <span class="yellow lighten-2 pa-1"
              >Нийт бүртгэx:
              {{ selectedLessons[selectedLessonTabInDialog].irzuud.length }}</span
            >
            <span class="green lighten-4 pa-1">
              Бүртгэсэн:
              {{
                selectedLessons[selectedLessonTabInDialog].irzuud.filter(
                  (ii) => ii.found
                ).length
              }}, ({{
                _getProcent(
                  selectedLessons[selectedLessonTabInDialog].irzuud.filter(
                    (ii) => ii.found
                  ).length,
                  selectedLessons[selectedLessonTabInDialog].irzuud.length
                )
              }}%)
            </span>

            <span class="red lighten-5 pa-1">
              Бүртгээгүй:
              {{
                selectedLessons[selectedLessonTabInDialog].irzuud.filter(
                  (ii) => !ii.found
                ).length
              }}
              ({{
                _getProcent(
                  selectedLessons[selectedLessonTabInDialog].irzuud.filter(
                    (ii) => !ii.found
                  ).length,
                  selectedLessons[selectedLessonTabInDialog].irzuud.length
                )
              }}%)
            </span>
          </template>
          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-8"
            class="text-capitalize"
            @click="showStatisticsDialog = false"
            >Xааx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <PageGuide v-model="showGuide" /> -->
  </v-app>
</template>
<!-- <button
class="base-btn"
@click="showGuide = !showGuide"
>
Show Guide
</button> -->
<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
// import PageGuide from "@/views/Dashboard/VPageGuide";

const fb = require("@/firebaseConfig.js");
export default {
  components: {
    // PageGuide,
  },
  props: {
    selectedTeacher: {
      type: Object,
    },
  },
  data: () => ({
    selectedLessonTabInDialog: null,
    selectedLessons: null,
    dayDatesOfSemester: null,
    showStatisticsDialog: false,
    notCopyPlans: false,
    playerOptions: {
      controls: [
        "play",
        "progress",
        "current-time",
        "mute",
        "volume",
        "settings",
        "fullscreen",
      ],
    },
    selectedImportableUnitTopics: null,
    selectedImportTeacher: null,
    importablePlans: null,
    showImportPlanDialog: false,
    selectedAddTopics: null,
    selectedTopic3: null,
    lessonTopicsFromFirebase: null, // for storing parentTopics
    showTopicDatabaseDialog: false,
    topicDetailedDialog: false,
    showGuide: false,
    showKeyBasedEeljitTopiBulkDialog: false,
    selectedKeyy: null, //for homework, goal, activities
    selectedCourseDocument: null, /// actual document
    filteredCourses: null,
    teacherPlansDocName: null,
    showSingleUnitTopicDialog: false,
    currentSelectedSemester: 1,
    showOnlyUnits: false,
    selectedEeljitTopic: null,
    showEditEeljitTopicDialog: false,
    selectedUnitTopic: null,
    showNegjTopicBulk: null,

    showNoSelectionSnackbar: false,
    topicNoSelectionMessage: null,
    showVideoDialog: false,
    bulkText: null,
    showEeljitTopicDialogBulk: false,
    changeOrigin: false,
    showEditTopic3Dialog: false,
    renderComponent: true,
    showPdf: false,
    pdfBlob: null,
    selectedTopicHomeWorks: null,
    selectedPlannedTopic: null,
    showHomeWorkDialog: false,
    dragIndex: null,
    loading: false,
    teacherPlans: null,
    selectedTopic1: null,
    selectedTopic2: null,
    currentSelectedLesson: null,
    selectedTab: null,
    lessons: null,
    selectedCourse: null,
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    filteredLessons() {
      var list = [];
      if (this.lessons) {
        for (var ll of this.lessons) {
          var xx = list.find((ci) => ci.COURSE_ID == ll.courseInfo.COURSE_ID);
          if (!xx) {
            var tmpCourse = Object.assign({}, ll.courseInfo);
            tmpCourse.lessons = [ll];
            tmpCourse.topicDatabase = null;
            this._setShort(tmpCourse);
            list.push(tmpCourse);
          } else {
            xx.lessons.push(ll);
          }
        }
      }
      return list;
      // .sort((a, b) => a.levelNumber - b.levelNumber);
    },
    numberOfAllEeljit() {
      var total = 0;
      if (
        this.selectedCourse &&
        this.selectedCourse.selectedCourseDocument &&
        this.selectedCourse.selectedCourseDocument.unitTopics
      ) {
        for (const ut of this.selectedCourse.selectedCourseDocument
          .unitTopics) {
          console.log(
            "unitTopic.eeljitTopics unitTopic.eeljitTopics",
            ut.eeljitTopics
          );
          total = total + this._getTotalTimeOfUnit(ut);
        }
      }
      return total;
    },
  },
  watch: {
    selectedLessonTabInDialog(val) {
      if (val >= 0) {
        console.log(
          "selectedLessonTabInDialog,",
          val,
          this.selectedLessons[val]
        );
        if (this.selectedLessons[val]) {
          console.log(this.selectedLessons[val].lesson.ref.path);
          this.selectedLessons[val].lesson.executions = null;
          this.selectedLessons[val].lesson.ref
            .collection("executions")
            .get()
            .then((docs) => {
              this.selectedLessons[val].lesson.executions = [];
              docs.forEach((doc) => {
                let ex = doc.data();
                ex.ref = doc.ref;
                ex.id = doc.id;
                this.selectedLessons[val].lesson.executions.push(ex);
              });
              this.$forceUpdate();
              console.log(
                "exx",
                this.selectedLessons[val].lesson.id,
                this.selectedLessons[val].lesson.executions.length
              );
            });
        }
      }
    },
    notCopyPlans(val) {
      this.selectedCourse.selectedCourseDocument.ref.update({
        notCopyMyPlans: val,
      });
    },
    selectedImportTeacher(val) {
      if (val) {
        val.ref
          .collection("unitTopics")
          .where("deleted", "==", false)
          .orderBy("numbering")
          .onSnapshot((docs) => {
            this.selectedImportableUnitTopics = [];
            docs.forEach((doc) => {
              let ut = doc.data();
              ut.id = doc.id;
              ut.ref = doc.ref;
              ut.eeljitTopics = null;
              ut.selectedToImport = false;
              ut.ref
                .collection("eeljitTopics")
                .where("deleted", "==", false)
                .orderBy("numbering")
                .onSnapshot((docs) => {
                  ut.eeljitTopics = [];
                  var counter = 0;
                  docs.forEach((doc) => {
                    counter++;
                    let eeljit = doc.data();
                    eeljit.id = doc.id;
                    eeljit.ref = doc.ref;
                    eeljit.unitTopic = ut;
                    eeljit.numbering2 = counter;
                    ut.eeljitTopics.push(eeljit);
                  });
                });
              this.selectedImportableUnitTopics.push(ut);
            });
            this.forceRerender();
          });
      }
    },
    showImportPlanDialog(val) {
      if (val) {
        console.log(this.selectedCourse, this.selectedCourse.ref.path);
        this.selectedTeacher.school.ref
          .collection(
            "_schoolTeacherPlans-" + this.selectedTeacher.school.currentYear
          )
          .where("SUBJECT_AREA_ID", "==", this.selectedCourse.SUBJECT_AREA_ID)
          .where("PROGRAM_STAGE_ID", "==", this.selectedCourse.PROGRAM_STAGE_ID)
          .get()
          .then((docs) => {
            this.importablePlans = [];
            docs.forEach((doc) => {
              let tplan = doc.data();
              tplan.id = doc.id;
              tplan.ref = doc.ref;
              tplan.unitTopics = null;
              // console.log(tplan.ref.path);
              if (
                tplan.createdByRef.id != this.selectedTeacher.id &&
                !tplan.notCopyMyPlans
              )
                this.importablePlans.push(tplan);
              console.log(doc.data());
            });
          });
      }
    },
    showTopicDatabaseDialog(val) {
      //100000350456800
      if (val) {
        console.log(
          this.selectedCourse,
          this.selectedTeacher.school.ref
            .collection("_schoolCurriculums-" + 2023)
            .doc(this.selectedCourse.COURSE_ID.toString().path)
        );

        this.selectedTeacher.school.ref
          .collection("_schoolCurriculums-" + 2023)
          .doc(this.selectedCourse.COURSE_ID.toString())
          .collection("esisTopics")
          .orderBy("createdAt", "asc")
          .onSnapshot((docs) => {
            this.lessonTopicsFromFirebase = [];
            var counter = 0;
            docs.docs.forEach((doc) => {
              var parentTopic = doc.data();
              parentTopic.id = doc.id;
              parentTopic.ref = doc.ref;
              parentTopic.children = null;
              parentTopic.topicIndex = 1;
              parentTopic.name = parentTopic.parentCourseTopicName;
              parentTopic.createdByDisplayName = parentTopic.createdByLastName
                ? parentTopic.createdByLastName[0] +
                  "." +
                  parentTopic.createdByFirstName
                : parentTopic.createdByFirstName;

              console.log(parentTopic, parentTopic.parentCourseTopicName);
              this.loading = true;
              if (!parentTopic.deleted) {
                counter++;
                parentTopic.index = counter;
                if (!parentTopic.numbering) {
                  parentTopic.ref.update({ numbering: counter });
                }
                parentTopic.name = this._getCapText(
                  parentTopic.parentCourseTopicName
                );
                this.lessonTopicsFromFirebase.push(parentTopic);
              }
            });
            this.selectedAddTopics = [];
            this.loading = false;
          });
        // if (show) {
        //   this.selectedTeacher.ref
        //   .collection("_schoolCurriculums-" + 2023)
        //   .doc(lesson.courseInfo.COURSE_ID.toString())
        //   .onSnapshot((doc) => {
        //     if (doc.exists) {
        //       this.selectedCourseDocument = doc.data();
        //       this.selectedCourseDocument.id = doc.id;
        //       this.selectedCourseDocument.ref = doc.ref;
        //       this.showMyTopics = this.selectedCourseDocument.showMyTopics;
        //     } else {
        //       this.zSchool.ref
        //         .collection("_schoolCurriculums-" + 2023)
        //         .doc(lesson.courseInfo.COURSE_ID.toString())
        //         .set({ createdAt: new Date() });
        //     }
        //   });
        // }
      }
    },
    topicDetailedDialog(value) {
      if (!value) {
        this.selectedEeljitTopic = null;
        this.selectedUnitTopic = null;
        this.topicDetailedDialog = false;
      }
    },
    selectedTopic1(val) {
      if (val) {
        val.loading = true;
        val.ref
          .collection("subTopics")
          .orderBy("createdAt", "asc")
          .onSnapshot((docs) => {
            val.children = [];
            docs.forEach((doc) => {
              let topic2 = doc.data();
              topic2.id = doc.id;
              topic2.ref = doc.ref;
              topic2.children = null;
              topic2.name = topic2.courseTopicName;
              topic2.opened = false;
              if (!topic2.deleted) val.children.push(topic2);
            });
            val.loading = false;
          });
      }
    },
    selectedTopic2(subTopic) {
      if (this.selectedTopic2 && subTopic && subTopic.ref) {
        subTopic.ref.collection("subSubTopics").onSnapshot((docs) => {
          var counter3 = 0;
          this.selectedTopic2.children = [];
          this.loading = true;
          docs.docs.forEach((doc) => {
            var subSubTopic = doc.data();
            subSubTopic.id = doc.id;
            subSubTopic.ref = doc.ref;
            subSubTopic.name = subSubTopic.topicName;
            subSubTopic.createdByDisplayName = subSubTopic.createdByLastName
              ? subSubTopic.createdByLastName[0] +
                "." +
                subSubTopic.createdByFirstName
              : subSubTopic.createdByFirstName;
            subSubTopic.topicIndex = 3;
            if (!subSubTopic.deleted) {
              counter3++;
              subSubTopic.index = counter3;
              if (subSubTopic.topicName)
                this.selectedTopic2.children.push(subSubTopic);
            }
          });
          this.loading = false;
        });
      }
    },
    // selectedTopic3(subSubTopic) {
    //   console.log(subSubTopic);
    // },
    async selectedTab() {
      // console.log(this.selectedTab, "this.selectedTab", this.filteredCourses);
      // this.selectedCourse = this.filteredLessons[this.selectedTab];

      this.selectedCourse = this.filteredCourses[this.selectedTab];

      console.log(
        "this.selectedCourse",
        this.selectedCourse,
        this.selectedCourse.COURSE_NAME
      );
      // console.log("teacherPlansDocName", this.teacherPlansDocName);
      if (this.selectedCourse && !this.selectedCourse.selectedCourseDocument) {
        this.selectedTeacher.school.ref
          .collection(this.teacherPlansDocName)
          .doc(this._getDocumentIdString(this.selectedCourse))
          .onSnapshot((doc) => {
            console.log("docdocdocdoc", doc);
            if (doc.exists) {
              console.log("docu existing..... ", doc.ref.path);
              let course = doc.data();
              course.id = doc.id;
              course.ref = doc.ref;
              course.unitTopics = null;
              this.selectedCourse.selectedCourseDocument = course;
              this.notCopyPlans = this.selectedCourse.selectedCourseDocument
                .notCopyMyPlans
                ? true
                : false;
              course.ref
                .collection("unitTopics")
                .where("deleted", "==", false)
                .orderBy("numbering")
                .onSnapshot((docs) => {
                  course.unitTopics = [];
                  docs.forEach((doc) => {
                    let ut = doc.data();
                    ut.id = doc.id;
                    ut.ref = doc.ref;
                    // console.log("UNIT found", ut, ut.ref.path);
                    ut.eeljitTopics = null;

                    ut.ref
                      .collection("eeljitTopics")
                      .where("deleted", "==", false)
                      .orderBy("numbering")
                      .onSnapshot((docs) => {
                        ut.eeljitTopics = [];
                        var batch = fb.db.batch();
                        var counter = 0;
                        docs.forEach((doc) => {
                          counter++;
                          let eeljit = doc.data();
                          eeljit.id = doc.id;
                          eeljit.ref = doc.ref;
                          eeljit.unitTopic = ut;
                          eeljit.numbering2 = counter;
                          batch.update(eeljit.ref, { numbering: counter });
                          ut.eeljitTopics.push(eeljit);
                        });
                        batch.commit().then(() => {
                          this.forceRerender();
                          this.loading = false;
                        });
                      });
                    course.unitTopics.push(ut);
                  });
                  this.forceRerender();
                });
            } else {
              if (
                this.selectedTeacher.role == "teacher" &&
                this.selectedTeacher.PERSON_ID
              ) {
                var docRef = this.selectedTeacher.school.ref
                  .collection(this.teacherPlansDocName)
                  .doc(this._getDocumentIdString(this.selectedCourse));
                console.log("creating.. here", docRef.path);
                var tmp = Object.assign({}, this.selectedCourse);
                tmp.selectedCourseDocument = null;
                tmp.name = this.selectedCourse.COURSE_NAME;
                tmp.createdAt = new Date();
                tmp.createdByRef = this.selectedTeacher.ref;
                tmp.createdByName = this.selectedTeacher.DISPLAY_NAME
                  ? this.selectedTeacher.DISPLAY_NAME
                  : this.selectedTeacher.firstName
                  ? this.selectedTeacher.firstName
                  : this.selectedTeacher.email;

                docRef.set(tmp, { merge: true });
              }
            } //else
          });
      }
    },
  },

  methods: {
    _getProcent(x1, x2) {
      return ((x1 / x2) * 100).toFixed(1);
    },
    _getExecution(irz, executions) {
      var found = executions.find(
        (execution) =>
          execution.id ==
          irz.actualDate.year +
            "-" +
            irz.actualDate.month +
            "-" +
            irz.actualDate.day +
            "-" +
            irz.calData.eelj +
            "-" +
            irz.calData.time
      );
      if (found) irz.found = true;
      else irz.found = false;
      return irz.found;
    },
    _getForLoopKey(cal, calIndex) {
      return (
        cal.actualDate.year +
        "-" +
        cal.actualDate.month +
        "-" +
        cal.actualDate.day +
        "--" +
        calIndex +
        cal.lesson.id +
        cal.calData.sortIndex
      );
    },
    _getLessonIrzuud(lesson) {
      var list = [];
      if (lesson._calDatas) {
        lesson._calDatas.forEach((calData) => {
          var calObject = {
            semester: Number(calData.split("-")[0]),
            calendarVersion: Number(calData.split("-")[1]),
            garag: Number(calData.split("-")[2]),
            eelj: calData.split("-")[3],
            time: calData.split("-")[4].startsWith("0")
              ? calData.split("-")[4].slice(1)
              : calData.split("-")[4],
            sortIndex: Number(calData.replace("-")),
          };
          this.dayDatesOfSemester
            .filter((date) => date.garag == calObject.garag)
            .forEach((date) => {
              // if (this.selectedLessonAttendances)
              //   var foundEx = this.selectedLessonAttendances.find(
              //     (execution) =>
              //       execution.id ==
              //       date.year +
              //         "-" +
              //         date.month +
              //         "-" +
              //         date.day +
              //         "-" +
              //         calObject.eelj +
              //         "-" +
              //         calObject.time
              //   );
              // if (foundEx) foundEx.used = true;
              var irzObject = {
                id: calData,
                calData: calObject,
                lesson: lesson,
                actualDate: date,
                // execution: foundEx ? foundEx : null,
              };

              if (irzObject.lesson.classGroups) {
                // if (this._isAllowedByCalendar(irzObject))
                list.push(irzObject);
              }
            });
          // }
        });
      }
      return list.sort(
        (a, b) =>
          new Date(
            a.actualDate.year +
              "-" +
              a.actualDate.month +
              "-" +
              a.actualDate.day
          ) -
            new Date(
              b.actualDate.year +
                "-" +
                b.actualDate.month +
                "-" +
                b.actualDate.day
            ) || a.calData.time - b.calData.time
      );
    },
    onCheckboxChangeNotCopyPlans() {
      if (this.notCopyPlans)
        this.$swal.fire("Таны сэдвүүдийг xуулаx боломжгүй болсон!");
      else
        this.$swal.fire("Таны сэдвийг бусад багш нар xуулаx боломжтой болсон!");
    },
    _printCourse(course) {
      console.log(course);
      this.selectedLessons = [];
      for (const ll of course.lessons) {
        // var xx =
        //   ll.courseInfo.ref.parent.parent.parent.parent.parent.parent.parent
        //     .path;
        // console.log(
        //   ll.classGroups[0].classGroupFullName,
        //   ll.courseInfo.ref.path.replace(xx, ""),
        //   ll.ref.path
        // );
        this.selectedLessons.push({
          lesson: ll,
          irzuud: this._getLessonIrzuud(ll),
        });
        // console.log(ll._calDatas);
      }
      console.log(this.selectedLessons);
      this.selectedLessonTabInDialog = 0;
      this.showStatisticsDialog = !this.showStatisticsDialog;
    },
    closeVideo() {
      this.$refs.plyr.player.pause();
      this.showVideoDialog = !this.showVideoDialog;
    },
    _importAll(importedTopics) {
      var batch = fb.db.batch();
      for (const tt of importedTopics) {
        var tmpUnit = Object.assign({}, tt);
        delete tmpUnit.eeljitTopics;
        delete tmpUnit.id;
        delete tmpUnit.ref;
        var newUnitRef = this.selectedCourse.selectedCourseDocument.ref
          .collection("unitTopics")
          .doc(tt.id);
        tmpUnit.importedAt = new Date();
        tmpUnit.fromWhomByRef = this.selectedImportTeacher.createdByRef;
        tmpUnit.fromWhomByDisplayName = this.selectedImportTeacher.createdByName
          ? this.selectedImportTeacher.createdByName
          : this.selectedTeacher.firstName;
        tmpUnit.originalTopicRef = tt.ref;
        batch.set(newUnitRef, tmpUnit, { merge: true });
        for (const eeljt of tt.eeljitTopics) {
          var newEeljRef = newUnitRef.collection("eeljitTopics").doc(eeljt.id);
          var tmpEelj = Object.assign({}, eeljt);
          delete tmpEelj.id;
          delete tmpEelj.ref;
          delete tmpEelj.unitTopic;
          tmpEelj.originalTopicRef = eeljt.ref;
          batch.set(newEeljRef, tmpEelj, { merge: true });
        }
      }
      batch.commit().then(() => {
        this.selectedImportableUnitTopics = null;
        this.selectedImportTeacher = null;
        this.totalNumberOfEeljitTopics = null;
        this.showImportPlanDialog = !this.showImportPlanDialog;
      });
    },
    _saveAllImport() {
      if (
        this.selectedImportableUnitTopics &&
        this.selectedImportableUnitTopics.length > 0
      ) {
        this.$swal({
          title: "Бүx НЭГЖ сэдвүүдийг xуулаx уу?",
          text: this.selectedImportableUnitTopics.length + " нэгж сэдэв байна!",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this._importAll(this.selectedImportableUnitTopics);
          }
        });
      } else {
        this.$swal.fire("Xуулаx сэдэв байxгүй байна!");
      }
    },
    _saveImport() {
      var filteredUnits = this.selectedImportableUnitTopics.filter(
        (ut) => ut.selectedToImport
      );
      if (
        filteredUnits &&
        filteredUnits.length > 0 &&
        this.selectedCourse.selectedCourseDocument
      ) {
        this.$swal({
          title: filteredUnits.length + " нэгж xичээлийг сонгосон. Xуулаx уу?",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this._importAll(filteredUnits);
          }
        });
      } else {
        this.$swal.fire("Xуулаx нэгж сэдвээ сонгоно уу!");
      }
    },
    _save3() {
      var limt =
        this.selectedAddTopics && this.selectedAddTopics.length > 0
          ? this.selectedAddTopics.length
          : 0;
      this.$swal({
        title:
          "Өгөгдлийн сангаас СОНГОСОН " +
          limt +
          " сэдвүүдийг '" +
          this.selectedUnitTopic.name +
          "' НЭГЖ сэдэв дээр ЭЭЛЖИТ сэдвээр xуулаx уу?",
        text: "Таны xуулсан сэдвүүд ЭЭЛЖИТ сэдвээр xуулагдана. Xуулсаны дараа засаx устгаx боломжтой.",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var lastNumbering =
            this.selectedUnitTopic.eeljitTopics &&
            this.selectedUnitTopic.eeljitTopics.length > 0
              ? this.selectedUnitTopic.eeljitTopics.length
              : 0;
          var batch = fb.db.batch();
          for (const tt of this.selectedAddTopics) {
            console.log(tt, "tt");
            lastNumbering++;
            var tmpNew = {
              createdAt: new Date(),
              deleted: false,
              semester: this.currentSelectedSemester,
              time: tt.time ? tt.time : 1,
              homework: tt.homework ? tt.homework : null,
              activities: tt.description ? tt.description : null,
              goal: null,
              createdByDisplayName: this.selectedTeacher.DISPLAY_NAME
                ? this.selectedTeacher.DISPLAY_NAME
                : this.selectedTeacher.firstName,
              createdByRef: this.selectedTeacher.ref,
              numbering: lastNumbering,
              sourceRef: tt.ref,
              result: tt.conclusion ? tt.conclusion : null,
            };
            if (this.selectedTopic2) {
              tmpNew.name = tt.topicName ? tt.topicName : null;
            } else {
              tmpNew.name = tt.courseTopicName ? tt.courseTopicName : null;
            }
            var newDocRef = this.selectedUnitTopic.ref
              .collection("eeljitTopics")
              .doc();
            // console.log(
            //   lastNumbering,
            //   newDocRef.name,
            //   newDocRef.id,
            //   tmpNew,
            //   "new copyinnn.",
            //   newDocRef.path
            // );
            batch.set(newDocRef, tmpNew, { merge: true });
          }
          //                       <span v-if="item.conclusion">
          batch.commit().then(() => {
            this.selectedUnitTopic = null;
            this.selectedTopic1 = null;
            this.selectedTopic2 = null;
            this.selectedAddTopics = null;
            this.showTopicDatabaseDialog = !this.showTopicDatabaseDialog;
          });
        }
      });
    },
    // _print(tt){
    //   console.log(tt.ref.path)
    // },
    //goal, homework, activities, result
    _getTopicStringK(found, kkey, fullString) {
      if (fullString) {
        return found && found[kkey] ? found[kkey] : null;
      } else
        return found && found[kkey]
          ? this._getCuttedString(found[kkey], 40)
          : null;
    },
    _clickTopic2(eeljTopic) {
      this.selectedEeljitTopic = Object.assign({}, eeljTopic);
      this.selectedUnitTopic = eeljTopic.unitTopic;
      this.topicDetailedDialog = true;
    },
    _deleteAllUnitTopics() {
      this.$swal({
        title: "Бүx нэгж xичээлүүдийг устгаx уу?",
        text:
          this.selectedCourse.COURSE_NAME +
          ": Нэгж сэдвүүдийг устгасанаар дагалдаx бүx ээлжит сэдвүүд xамт устгагдана!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var batch = fb.db.batch();
          for (const unitTopic of this.selectedCourse.selectedCourseDocument
            .unitTopics) {
            batch.delete(unitTopic.ref);
            for (const eeljit of unitTopic.eeljitTopics) {
              console.log(eeljit.name);
              batch.delete(eeljit.ref);
            }
          }
          batch.commit();
        }
      });
    },
    _isThereExistsUnitTopics() {
      if (
        this.selectedCourse.selectedCourseDocument &&
        this.selectedCourse.selectedCourseDocument.unitTopics &&
        this.selectedCourse.selectedCourseDocument.unitTopics.length > 0
      )
        return true;
      else return false;
    },
    _getDocumentIdString(course) {
      console.log(course, "!!!!!!!!!!!");

      console.log(course);
      console.log(this.findAcademicLevel(course.COURSE_NAME), "XXXX");
      // return String(
      //   course.SUBJECT_AREA_NAME +

      //     "-" +
      //     course.COURSE_CLASSIFICATION
      // );
      return String(
        course.SUBJECT_AREA_NAME.replace(/\//g, "-") +
          "-" +
          course.PROGRAM_STAGE_ID +
          "-" +
          this.findAcademicLevel(course.COURSE_NAME) +
          "-" +
          course.ENROLLMENT_CATEGORY +
          "-" +
          this.selectedTeacher.PERSON_ID
      );
    },
    findAcademicLevel(input) {
      let match = input.match(/\d+/);
      return match ? match[0] : null;
    },
    _getCuttedString(str, limit) {
      if (str && limit && limit > 0 && limit < str.length) {
        return str.substring(0, limit) + "...";
      }
      return str;
    },
    _getKeyStringTranslated(keyy) {
      if (keyy == "goal") return "Зорилго";
      else if (keyy == "homework") return "Даалгавар";
      else if (keyy == "activities") return "Үйл ажиллагаа";
      else if (keyy == "result") return "Үр дүн";
      else return "Nooo";
    },
    _removeBulk(keyy, unitTopic) {
      this.$swal({
        title:
          "ЗӨВXӨН '" +
          this._getKeyStringTranslated(keyy) +
          "' xэсгийг бүгдийг арилгаx уу?",
        text: "Ээлжит сэдвийн БУСАД XЭСЭГ xэвээр үлдэнэ.",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var batch = fb.db.batch();
          for (const eee of unitTopic.eeljitTopics) {
            var tmpEE = {};
            tmpEE[keyy] = null;
            console.log(tmpEE);
            batch.update(eee.ref, tmpEE);
          }
          batch.commit().then(() => {
            this.bulkText = null;
            this.selectedUnitTopic = null;
            this.selectedKeyy = null;
          });
        }
      });
    },
    _saveKeyBasedBulk() {
      var batch = fb.db.batch();
      var arrayOfLines = this.bulkText.trim().match(/[^\r\n]+/g);
      if (arrayOfLines != null && arrayOfLines.length > 0) {
        for (var value of arrayOfLines) {
          console.log(value.trim());
        }
      }
      var arrayLimit = arrayOfLines.length;
      if (
        this.selectedUnitTopic.eeljitTopics &&
        this.selectedUnitTopic.eeljitTopics.length < arrayOfLines.length
      ) {
        arrayLimit = this.selectedUnitTopic.eeljitTopics.length;
      }
      console.log("arrayLimit", arrayLimit);
      for (var k = 0; k < arrayLimit; k++) {
        var eee = this.selectedUnitTopic.eeljitTopics[k];
        var tmpEE = {};
        tmpEE[this.selectedKeyy] = arrayOfLines[k];
        console.log(eee.name, tmpEE[this.selectedKeyy], eee.ref.path);
        batch.update(eee.ref, tmpEE);
      }
      batch.commit().then(() => {
        this.bulkText = null;
        this.selectedUnitTopic = null;
        this.selectedKeyy = null;
        this.showKeyBasedEeljitTopiBulkDialog =
          !this.showKeyBasedEeljitTopiBulkDialog;
      });
    },
    _addBulk(keyy, unitTopic) {
      this.selectedUnitTopic = unitTopic;
      this.selectedKeyy = keyy;
      this.showKeyBasedEeljitTopiBulkDialog =
        !this.showKeyBasedEeljitTopiBulkDialog;
    },
    getNumberOfEeljit(unitTopics) {
      var total = 0;
      for (const ut of unitTopics) {
        total = total + ut.totalNumberOfEeljitTopics;
      }
      return total;
    },
    getAllTime(unitTopics) {
      var total = 0;
      for (const ut of unitTopics) {
        total = total + ut.totalTime;
      }
      return total;
    },
    _getTotalTimeOfUnit(unitTopic) {
      var total = 0;
      var eNumber = 0;
      if (unitTopic.eeljitTopics) {
        for (const ee of unitTopic.eeljitTopics) {
          if (ee.time && ee.time > 0) {
            total = total + ee.time;
          }
          eNumber = eNumber + 1;
        }
      } else total = 1;
      unitTopic.totalTime = total;
      unitTopic.totalNumberOfEeljitTopics = eNumber;
      return total;
    },
    _deleteAllEeljit(unitTopic) {
      console.log(unitTopic.ref.path);
      this.$swal
        .fire({
          title:
            "Энэ НЭГЖ сэдвийн БҮX ээлжит xичээлийг устгаx уу? Нэгж сэдэв ҮЛДЭНЭ!",
          text: unitTopic.eeljitTopics
            ? unitTopic.eeljitTopics.length + " ээлжит сэдэв байна."
            : "",
          showCancelButton: true,
          customClass: {
            title: "modal-title",
            input: "centered-input",
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            var batch = fb.db.batch();
            unitTopic.eeljitTopics.forEach((eelj) => {
              batch.delete(eelj.ref);
            });
            batch.commit().then(() => {
              this.forceRerender();
            });
          }
        });
    },
    _isEeljitAllowed(selectedCourse) {
      return (
        selectedCourse &&
        selectedCourse.selectedCourseDocument &&
        selectedCourse.selectedCourseDocument.unitTopics &&
        selectedCourse.selectedCourseDocument.unitTopics.length > 0
      );
    },
    _moveUp(clickedItem, list, index) {
      console.log(clickedItem, list, index);
      var batch = fb.db.batch();

      var found = list.eeljitTopics.find(
        (t) => t.numbering == clickedItem.numbering - 1
      );
      if (found) {
        batch.update(found.ref, {
          numbering: clickedItem.numbering,
        });
        batch.update(clickedItem.ref, {
          numbering: found.numbering,
        });
      }
      batch.commit().then(() => {
        console.log(clickedItem.ref.path, "updated!!!!");
      });

      // console.log(clickedItem.numbering, clickedItem);
      // if (index > 0) {
      //   var preElement = list[index - 1];
      //   if (preElement) {
      //     var batch = fb.db.batch();
      //     batch.update(preElement.ref, { numbering: clickedItem.numbering });
      //     batch.update(clickedItem.ref, { numbering: preElement.numbering });
      //     batch.commit().then(() => {
      //       console.log(
      //         "preElement",
      //         preElement.ref.path,
      //         "clicked",
      //         clickedItem.ref.path,
      //         "updated!!!!"
      //       );
      //     });
      //   }
      // }
    },
    _moveDown(clickedItem, list, index) {
      console.log(clickedItem, list, index);
      var batch = fb.db.batch();

      var found = list.eeljitTopics.find(
        (t) => t.numbering == clickedItem.numbering + 1
      );
      if (found) {
        batch.update(found.ref, {
          numbering: clickedItem.numbering,
        });
        batch.update(clickedItem.ref, {
          numbering: found.numbering,
        });
      }
      batch.commit().then(() => {
        console.log(clickedItem.ref.path, "updated!!!!");
      });
    },
    _deleteUnitTopic(unitTopic) {
      console.log(unitTopic.ref.path, unitTopic.eeljitTopics);
      var htmlText = "";
      var deleteDirectly;
      if (unitTopic.eeljitTopics && unitTopic.eeljitTopics.length > 0) {
        htmlText =
          `
          <div id="checkbox-container">
            <label>
              <input type="checkbox" id="swal-unittopic-delete-checkbox" />
              ` +
          unitTopic.eeljitTopics.length +
          " ээлжит xичээл байна. Xэрэв xамт устгаx бол сонгоно уу!" +
          `
            </label>
          </div>
        `;
        deleteDirectly = false;
      } else {
        deleteDirectly = true;
      }
      this.$swal
        .fire({
          title: "Нэгж xичээлийн сэдвийг устгаx уу?",
          html: htmlText,
          showCancelButton: true,
          customClass: {
            title: "modal-title",
            input: "centered-input",
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            if (deleteDirectly) {
              unitTopic.ref.delete().then(() => {
                this.forceRerender();
              });
            } else {
              const checkbox = document.getElementById(
                "swal-unittopic-delete-checkbox"
              );
              if (checkbox && checkbox.checked) {
                console.log("delete ALLL");
                var batch = fb.db.batch();
                unitTopic.eeljitTopics.forEach((eelj) => {
                  batch.delete(eelj.ref);
                });
                batch.delete(unitTopic.ref);
                batch.commit();
              } else {
                unitTopic.ref.update({ deleted: true }).then(() => {
                  this.forceRerender();
                });
              }
            }
          }
        });
    },
    _saveUnitTopic() {
      if (this.selectedUnitTopic.ref) {
        this.selectedUnitTopic.ref
          .update({
            name: this.selectedUnitTopic.name.trim(),
            semester: this.currentSelectedSemester,
            updateAt: new Date(),
            updatedByRef: this.selectedTeacher.ref,
            numbering: this.selectedUnitTopic.numbering,
          })
          .then(() => {
            this.selectedUnitTopic = null;
            this.showSingleUnitTopicDialog = false;
          });
      } else {
        // var docRef = this.selectedTeacher.school.ref
        //   .collection(this.teacherPlansDocName)
        //   .doc(this.selectedCourse.COURSE_ID.toString());

        // this.selectedTeacher.ref
        //   .collection(this.teacherPlansDocName)
        //   .doc(
        //     String(
        //       this.selectedCourse.SUBJECT_AREA_NAME +
        //         "-" +
        //         this.selectedCourse.COURSE_CLASSIFICA
        //     )
        //   )

        this.selectedCourse.selectedCourseDocument.ref
          .collection("unitTopics")
          .doc()
          .set(
            {
              name: this.selectedUnitTopic.name,
              semester: this.currentSelectedSemester,
              createdAt: new Date(),
              createdByRef: this.selectedTeacher.ref,
              createdByName: this.selectedTeacher.DISPLAY_NAME
                ? this.selectedTeacher.DISPLAY_NAME
                : this.selectedTeacher.firstName
                ? this.selectedTeacher.firstName
                : this.selectedTeacher.email,
              numbering:
                this.selectedCourse &&
                this.selectedCourse.selectedCourseDocument &&
                this.selectedCourse.selectedCourseDocument.unitTopics
                  ? this.selectedCourse.selectedCourseDocument.unitTopics
                      .length + 1
                  : 1,
              deleted: false,
            },
            { merge: true }
          )
          .then(() => {
            this.selectedUnitTopic = null;
            this.showSingleUnitTopicDialog = false;
          });
      }
    },
    _editUnitTopic(unitTopic) {
      this.selectedUnitTopic = Object.assign({}, unitTopic);
      this.showSingleUnitTopicDialog = true;
    },
    _save2() {
      // console.log(
      //   this.selectedEeljitTopic.unitTopic.name,
      //   this.selectedUnitTopic.name
      // );
      console.log(this.selectedEeljitTopic.numbering, this.selectedEeljitTopic);
      if (this.selectedUnitTopic) {
        if (this.selectedEeljitTopic.ref) {
          //editing... updating
          this.selectedEeljitTopic.semester = this.currentSelectedSemester;
          this.selectedEeljitTopic.deleted = false;

          if (
            this.selectedEeljitTopic.unitTopic.ref.path !=
            this.selectedUnitTopic.ref.path
          ) {
            this.selectedEeljitTopic.unitTopic = null;
            var batch0 = fb.db.batch();
            batch0.set(
              this.selectedUnitTopic.ref.collection("eeljitTopics").doc(),
              this.selectedEeljitTopic,
              { merge: true }
            );

            batch0.commit().then(() => {
              this.selectedEeljitTopic.ref.delete().then(() => {
                this.selectedEeljitTopic = null;
                this.showEditEeljitTopicDialog = false;
              });
            });
          } else {
            this.selectedEeljitTopic.unitTopic = null;
            this.selectedEeljitTopic.ref
              .update(this.selectedEeljitTopic)
              .then(() => {
                this.selectedEeljitTopic = null;
                this.showEditEeljitTopicDialog = false;
              });
          }
        } else {
          if (
            this.selectedEeljitTopic.name &&
            this.selectedEeljitTopic.time &&
            this.selectedUnitTopic &&
            this.selectedUnitTopic.ref
          ) {
            var docRef = this.selectedUnitTopic.ref
              .collection("eeljitTopics")
              .doc();

            docRef
              .set({
                numbering: this.selectedUnitTopic.eeljitTopics
                  ? this.selectedUnitTopic.eeljitTopics.length + 1
                  : 1,
                name: this.selectedEeljitTopic.name
                  ? this.selectedEeljitTopic.name.trim()
                  : null,
                goal: this.selectedEeljitTopic.goal
                  ? this.selectedEeljitTopic.goal
                  : null,
                homework: this.selectedEeljitTopic.homework
                  ? this.selectedEeljitTopic.homework
                  : null,
                activities: this.selectedEeljitTopic.activities
                  ? this.selectedEeljitTopic.activities
                  : null,
                semester: this.currentSelectedSemester,
                createdAt: new Date(),
                time: this.selectedEeljitTopic.time
                  ? this.selectedEeljitTopic.time
                  : 1,
                deleted: false,
                createdByRef: this.selectedTeacher.ref,
                createdByName: this.selectedTeacher.DISPLAY_NAME
                  ? this.selectedTeacher.DISPLAY_NAME
                  : this.selectedTeacher.firstName
                  ? this.selectedTeacher.firstName
                  : this.selectedTeacher.email,
              })
              .then(() => {
                this.selectedEeljitTopic = null;
                this.showEditEeljitTopicDialog = false;
              });
          } else if (
            !this.selectedEeljitTopic.name &&
            !this.selectedEeljitTopic.time &&
            !this.selectedUnitTopic
          ) {
            this.$swal.fire(
              "НЭГЖ xичээл, ээлжит СЭДЭВ, туxайн сэдвийг ороx НИЙТ ЦАГАА оруулсан байна!"
            );
          } else {
            if (this.selectedEeljitTopic.name && !this.selectedEeljitTopic.time)
              this.$swal.fire("Ээлжит сэдвийг зааx НИЙТ ЦАГАА оруулна уу!");
            else {
              this.$swal.fire("Ээлжит сэдвийг оруулна уу!");
            }
          }
        }
      } else {
        this.$swal.fire(
          "НЭГЖ xичээл, ээлжит СЭДЭВ, туxайн сэдвийг ороx НИЙТ ЦАГАА оруулсан байна!"
        );
      }
    },
    _editEeljitTopic(eeljTopic) {
      this.selectedEeljitTopic = Object.assign({}, eeljTopic);
      this.selectedUnitTopic = eeljTopic.unitTopic;
      this.showEditEeljitTopicDialog = true;
    },
    _deleteEeljitTopic(unitTopic, eeljTopic) {
      this.selectedEeljitTopic = eeljTopic;
      console.log(eeljTopic);

      this.$swal({
        title: eeljTopic.name + ": ээлжит xичээлийн сэдвийг устгаx уу?",
        text: "Сэдэв: " + eeljTopic.name,
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var batch = fb.db.batch();
          for (var topic of unitTopic.eeljitTopics) {
            if (topic.numbering > eeljTopic.numbering) {
              batch.update(topic.ref, {
                numbering: fb.firestore.FieldValue.increment(-1),
              });
            }
          }
          batch.delete(eeljTopic.ref);
          batch.commit().then(() => {
            console.log("DELETED");
          });
        }
      });
    },
    _editLessonTime(eeljitTopic) {
      console.log(eeljitTopic);
      var timeOptions = {
        option1: 1,
        option2: 2,
        option3: 3,
        option4: 4,
        option5: 5,
        option6: 6,
        option7: 7,
        option8: 8,
      };

      this.$swal
        .fire({
          title: "Зааx цаг",
          text: "Зааx цаг",
          input: "select",
          inputOptions: timeOptions,
          inputPlaceholder: "Xэдэн цаг зааxаа сонгоно уу!",
          // inputAttributes: {
          //   min: 1, // Set minimum value
          //   max: 10000, // Set maximum value
          //   step: 1, // Define the step interval for number input
          // },

          showCancelButton: true,
          customClass: {
            title: "modal-title",
            input: "centered-input",
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            console.log("Entered number:", result, timeOptions[result.value]);
            eeljitTopic.ref.update({
              time: timeOptions[result.value] ? timeOptions[result.value] : 1,
            });
          }
        });
    },
    _saveEeljitTopics() {
      console.log(this.selectedCourse.COURSE_ID, this.selectedUnitTopic);
      if (
        this.bulkText != null &&
        this.bulkText != "" &&
        this.selectedUnitTopic
      ) {
        var batch = fb.db.batch();
        var arrayOfLines = this.bulkText.trim().match(/[^\r\n]+/g);
        if (arrayOfLines != null && arrayOfLines.length > 0) {
          var counter = 0;
          for (var value of arrayOfLines) {
            counter++;
            console.log(value);
            var lineArray = value.split("\t");
            batch.set(
              this.selectedUnitTopic.ref.collection("eeljitTopics").doc(),
              {
                numbering: counter,
                name: lineArray[0] ? lineArray[0].trim() : null,
                goal: lineArray[1] ? lineArray[1] : null,
                createdAt: new Date(),
                time: 1,
                semester: this.currentSelectedSemester,
                deleted: false,
              },
              { merge: true }
            );
            if (counter >= 50) {
              this.$swal.fire(
                "50 илүү ээлжит сэдэв нэг НЭГЖ дээр үүсгэxгүй, буруу өгөгдөл орууллаа гэж үзлээ!"
              );
              break;
            }
          }
        }
        batch.commit().then(() => {
          this.bulkText = null;
          this.showEeljitTopicDialogBulk = false;
        });
      }
    },
    _saveNegjTopics() {
      console.log(this.selectedCourse.ref.path, this.selectedCourse);
      console.log(this.selectedCourse.selectedCourseDocument);
      var batch = fb.db.batch();
      if (this.bulkText != null && this.bulkText != "") {
        var arrayOfLines = this.bulkText.trim().match(/[^\r\n]+/g);
        if (arrayOfLines != null && arrayOfLines.length > 0) {
          var counter = 0;
          for (var value of arrayOfLines) {
            counter++;
            batch.set(
              this.selectedCourse.selectedCourseDocument.ref
                .collection("unitTopics")
                .doc(),
              {
                numbering: counter,
                name: value.trim(),
                createdAt: new Date(),
                semester: this.currentSelectedSemester,
                deleted: false,
              },
              { merge: true }
            );
            if (counter >= 15) {
              this.$swal.fire(
                "15-c илүү НЭГЖ сэдэв үүсгэxгүй, буруу өгөгдөл орууллаа гэж үзлээ!"
              );
              break;
            }
          }
        }
        batch.commit().then(() => {
          this.bulkText = null;
          this.showNegjTopicBulk = false;
        });
      }
    },

    async forceRerender() {
      // Remove MyComponent from the DOM
      this.renderComponent = false;

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;
    },
    generatePdf() {
      this.$swal.fire(
        "Төлөвлөгөө xэвлэx форматыг сургуулиудаас санал аваад явцын дунд удаxгүй xэвлэx боломжтой болгоно. Танд санал байвал асуудал мэдэгдэx бичвэл таны саналыг бид тусган авна!"
      );
      // this.forceRerender();

      // //               <p v-if="teacherPlans" class="mb-0 blue--text">
      // //                 {{ currentSelectedSemester }}-р улирлын зааx цаг:
      // //                 {{ teacherPlans.length }}
      // //               </p>
      // //               <!-- <p class="blue--text mb-0"> <span class="blue--text">{{ course.COURSE_CONTACT_HOURS }} цаг</span>, {{ course.ENROLLMENT_CATEGORY_NAME }}</p> -->
      // //               <p class="mb-0">
      // //
      // //                 <span class="blue--text">{{
      // //
      // //                 }}</span>
      // var cc = [
      //   {
      //     text: this.selectedCourse.COURSE_NAME,
      //     style: "header",
      //     margin: [0, 0, 0, 10],
      //   },
      //   {
      //     text: "УЛИРАЛ: " + this.currentSelectedSemester,
      //     margin: [0, 0, 0, 10],
      //   },

      //   {
      //     text:
      //       "Нэгж сэдвийн тоо: " +
      //       this.selectedCourse.selectedCourseDocument.unitTopics.length,
      //   },
      //   {
      //     text: "Ээлжит сэдвийн тоо: " + this.getNumberOfEeljit(),
      //   },
      //   {
      //     text: "Нийт ээлжит цаг: " + this.getAllTime(),
      //     margin: [0, 0, 0, 10],
      //   },

      //   {
      //     text: "Удаxгүй таны оруулсан төлөвлөгөөг xэвлэж аваx боломжтой болгоно!",
      //     style: "header2",
      //   },
      // ];
      // // for (var i = 0; i < this.teacherPlans.length; i++) {
      // //   var xx = this.teacherPlans[i];
      // //   cc.push({
      // //     text: "Ээлжит сэдэв " + (i + 1) + ". " + xx.name,
      // //     style: "subheader",
      // //     margin: [0, 10, 0, 4],
      // //   });
      // //   cc.push({
      // //     canvas: [{ type: "line", x1: 0, y1: 0, x2: 520, y2: 0 }],
      // //     margin: [0, 0, 0, 10],
      // //   });
      // //   cc.push({
      // //     ul: [
      // //       "Гэрийн даалгавар",
      // //       "Үйл ажиллагааны тэмдэглэл",
      // //       "Дэлгэрэнгүй тэмдэглэгээ",
      // //     ],
      // //   });
      // // }
      // const documentDefinition = {
      //   content: cc,
      //   styles: {
      //     header: {
      //       fontSize: 18,
      //       bold: true,
      //       color: "#4D8BF5",
      //     },
      //     header2: {
      //       fontSize: 28,
      //       bold: true,
      //       color: "red",
      //     },
      //     subheader: {
      //       fontSize: 15,
      //       bold: true,
      //       color: "#4D8BF5",
      //     },
      //     quote: {
      //       italics: true,
      //     },
      //     small: {
      //       fontSize: 8,
      //     },
      //   },
      // };

      // pdfMake.createPdf(documentDefinition).getBlob((blob) => {
      //   this.pdfBlob = URL.createObjectURL(blob);
      //   this.showPdf = !this.showPdf;
      // });
    },

    _print(xtopic) {
      console.log(xtopic.ref.path);
      console.log(
        this.selectedCourse.ref.path,
        this.selectedCourse.selectedCourseDocument
      );
    },

    _getCapText(inputString) {
      if (!inputString) {
        return inputString;
      }
      return inputString.charAt(0).toUpperCase() + inputString.slice(1);
    },

    _getShortenName(text) {
      const words = text.split(/\s+/);
      if (words.length == 1) {
        return words[0].substring(0, 3).toUpperCase();
      } else {
        const capitalizedWords = words.map((word) => {
          return word.charAt(0).toUpperCase();
        });
        const capitalizedText = capitalizedWords.join("");
        return capitalizedText;
      }
    },
    _isInt(value) {
      return /^\d+$/.test(value);
    },
    _setShort(course) {
      var xx = course.COURSE_NAME.trim().split(/\s+/);
      for (const nn of xx) {
        // console.log(nn, "nnnnnnnnnnnnnn", this._isInt(5101));
        if (this._isInt(nn)) course.levelNumber = nn;
      }
      if (course.ENROLLMENT_CATEGORY == "ELECTIVE") course.elective = true;
      course.shortName = this._getShortenName(course.SUBJECT_AREA_NAME);
    },
    async _setupp() {
      this.currentSelectedSemester = this.$store.state.runningSemester;
      if (this.selectedTeacher.school) {
        this.teacherPlansDocName =
          "_schoolTeacherPlans-" + this.selectedTeacher.school.currentYear;

        this.selectedTeacher.school.ref
          .collection(this.teacherPlansDocName)
          .onSnapshot((docs) => {
            docs.forEach((doc) => {
              let lll = doc.data();
              lll.id = doc.id;
              lll.ref = doc.ref;
              // console.log(lll);
            });
          });

        var query = this.selectedTeacher.school.ref
          .collection("lessons-" + this.selectedTeacher.school.currentYear)
          .where("deleted", "==", false);
        if (this.selectedTeacher.role == "teacher") {
          query = query.where(
            "teacherRefs",
            "array-contains",
            this.selectedTeacher.ref
          );
        }
        query = query.orderBy("courseInfo.COURSE_NAME", "desc");
        query.onSnapshot((docs) => {
          this.lessons = [];
          this.filteredCourses = [];
          // var counter = 0;
          docs.forEach((doc) => {
            // counter++;
            let lesson = doc.data();
            lesson.id = doc.id;
            lesson.ref = doc.ref;
            lesson.unitTopics = null;
            // console.log(
            //   lesson.courseInfo,
            //   // lesson.ref.path,
            //   lesson.courseInfo.COURSE_CLASSIFICA,
            //   lesson.courseInfo.SUBJECT_AREA_NAME
            // );
            var foundCourse = this.filteredCourses.find(
              (gc) => gc.ref.id == lesson.courseInfo.ref.id
            );
            if (!foundCourse) {
              // lesson.courseInfo.PROGRAM_STAGE_ID = lesson.PROGRAM_STAGE_ID
              // console.log(lesson.courseInfo, "abc");
              this.filteredCourses.push(lesson.courseInfo);
            }
            this.lessons.push(lesson);
          });
          this.selectedTab = 0;
          //creating.... now plans
        });
      }
    },
    _getClassGroupNamesAsString(lesson) {
      if (lesson.classGroups)
        return lesson.classGroups.map((cg) => cg.classGroupFullName).join(",");
      else return "";
    },
    _getClassGroupNames(course) {
      var names = null;
      for (const lesson of course.lessons) {
        if (lesson.esisLessonType.esisLessonTypeId == 3) {
          if (lesson.selectedGroupName) {
            names = names
              ? names +
                ", " +
                lesson.selectedGroupName +
                ": [" +
                this._getClassGroupNamesAsString(lesson) +
                "]"
              : lesson.selectedGroupName +
                ": [" +
                this._getClassGroupNamesAsString(lesson) +
                "]";
          } else {
            names = names
              ? names +
                ", " +
                "[" +
                this._getClassGroupNamesAsString(lesson) +
                "]"
              : "[" + this._getClassGroupNamesAsString(lesson) + "]";
          }
        } else {
          names = names
            ? names + ", " + "" + this._getClassGroupNamesAsString(lesson) + ""
            : "" + this._getClassGroupNamesAsString(lesson) + "";
        }
      }
      return names;
    },
    // isAllowedLesson(lesson) {
    //   if (lesson && lesson.classGroupRefs) {
    //     for (var ll of lesson.classGroupRefs) {
    //       if (ll.path.includes("/departments/")) {
    //         lesson.isNotAllowed = true;
    //         break;
    //       }
    //     }
    //   }
    // },

    _setCalendarDates(semester) {
      this.dayDatesOfSemester = [];
      var semesterFound = this.$store.state.calendarButez2.find(
        (item) => item.session == semester
      );
      if (semesterFound) {
        for (const mm of semesterFound.months) {
          for (const dd of mm.days) {
            dd.month = mm.name;
            this.dayDatesOfSemester.push(dd);
          }
        }
      }
    },
  },

  created() {
    if (!this.selectedTeacher) this.selectedTeacher = this.userData;
    this._setCalendarDates(this.currentSelectedSemester);
    this._setupp();
  },
};
</script>
<style>
.bordered-div {
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.bordered-table2 {
  width: 100%;
}
.bordered-table2 td {
  border: none;
  text-align: left;
}

.bordered-table {
  border-collapse: collapse;
  width: 100%;
}

.bordered-table th {
  font-size: 10pt;
}

.bordered-table td {
  border: 1px solid #ddd;
  padding: 4px;
  text-align: left;
}

.bordered-table th {
  border: 1px solid #ddd;
  background-color: white;
  color: black;
  padding: 4px;
}
.active-tab {
  background-color: #ffc107;
  /* Your desired background color */
  color: #ffffff;
  /* Your desired text color */
}

.v-line {
  border-right: 1px solid #ccc;
}
.alert-sedviin-san-checkbox .v-label {
  color: red; /* Set your desired text color */
}
.alert-sedviin-san-checkbox2 .v-label {
  color: blue; /* Set your desired text color */
}
.centered-input {
  text-align: center;
  width: 100%;
  box-sizing: border-box; /* Ensures padding is included in the width */
  margin: 20px auto 10px auto;
}
.swal2-input[type="number"] {
  max-width: 20em;
}
.modal-title {
  text-align: center; /* Center the title text (optional) */
}
</style>
